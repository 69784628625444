<div *ngIf="loader==false || !loading">
  <div class="row mt-2">
    <div class="col-md-4">

    </div>
    <div class="col-md-4">

    </div>
    <div *ngIf="rechargeT=='fetch'" class="col-md-4 text-right pr-2">


      <!--  <div class="col-md-4 text-right pr-4">
              <button
                       mat-raised-button
                       (click)="openXl(contentWithdraw)"
                       matTooltip="Wallet Withdraw"
                       class="text-right order-button text-white mr-3"
                     >
                       Wallet Withdraw
                     </button>
                  
            </div> -->

    </div>

  </div>

</div>
<div *ngIf="loader==false && loading">

  <div *ngIf="rechargeT=='fetch'" class="container-fluid">
    <div class="row mt-2">

      <div class="col-md-4">

        <button mat-raised-button [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor"
          matTooltip="Back To Home" class="text-right order-button  mr-3" routerLink="/dashboard">{{"Back To Home" |
          translate}}
        </button>

      </div>
      <div class="col-md-4 text-right pr-2">
        <button mat-raised-button (click)="action('add')" matTooltip="Recharge Wallet"
          class="text-right order-button mr-3" [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor">
          {{"Package Recharge" | translate}}
        </button>
      </div>

    </div>


  </div>
  <!-- </div> -->


  <!-- start for deducted amount model -->
  <div class="row justify-content-center mt-2 mb-3 w-100">
    <div *ngIf="rechargeT=='add'" class="border col-lg-5 card p-3">
      <!-- start table -->
      <form [formGroup]="myRecharge">
        <!-- {{myRecharge.controls.amount2.value}} -->

        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">

            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>{{"Select Package" | translate}}</mat-label>
              <mat-select id="country" formControlName="package_id" required>
                <mat-option class="" (click)="getPack2($event.value,allResults.price,$event,allResults)"
                  *ngFor="let allResults of allResult;i as index;" value="{{allResults.package_id}}">
                  {{allResults.package_name}}
                </mat-option>

              </mat-select>
            </mat-form-field>


          </div>
          <div class="col-lg-6" *ngIf="usertypeid=='2'">
            <mat-form-field class="example-full-width " appearance="fill">
              <mat-label>{{"Select Company Name" | translate}} </mat-label>
              <mat-select id="country2" formControlName="comp_num" required>
                <mat-option class="text" *ngFor="let actual_data2 of actual_data;j as index;"
                  (click)="getPack3($event.value,actual_data2.comp_num)" value="{{actual_data2.comp_num}}">
                  {{actual_data2.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>



        </div>


      </form>
      <!-- start for detail package -->

      <mat-card style="padding-left: 17px;

        padding-right: 17px; 
        padding-bottom: 2px; 
        padding-top: 5px;
        margin-top: 5px;     
        /* margin-left: 0.2em; */
        background: #fff" class="" *ngIf="package_d">
        <div class=" d-flex border-bottom  mt-0">
          <div class="m-0 product-border-heading text-center"
            style="font-size: 14px; font-weight: 600; letter-spacing: 1px;">
            {{"Package Details" | translate}}
          </div>

        </div>
        <!-- start package -->


        <div class="row mt-1">

          <h6 class="col-md-12">{{"Package Name" | translate}} : {{ package_d?.package_name }}</h6>
          <h6 class="col-md-6">{{"Price" | translate}} : {{ package_d?.price | currency:'INR'}}</h6>

          <h6 *ngIf="package_d?.order_count !=-1" class="col-md-6">{{"Order Count" | translate}} : {{
            package_d?.order_count }}</h6>
          <h6 *ngIf="package_d?.order_count ==-1" class="col-md-6">{{"Order Count" | translate}} : {{"Unlimited" |
            translate}}</h6>

          <h6 *ngIf="package_d?.product_count !=-1" class="col-md-6">{{"Product Count" | translate}} : {{
            package_d?.product_count }}</h6>
          <h6 *ngIf="package_d?.product_count ==-1" class="col-md-6">{{"Product Count" | translate}} : {{"Unlimited" |
            translate}}</h6>

          <div class="col-md-6">
            <h6 class="font-weight-500">{{"Web Publish" | translate}} : &nbsp;
              <span *ngIf="package_d?.web_publish=='Y'"><i class="fas fa-check  text-success"></i></span>
              <span *ngIf="package_d?.web_publish=='N' "><i class="fas fa-times  text-danger"></i></span>
            </h6>
          </div>
          <div class="col-md-6">
            <h6 class="font-weight-500">{{"Commission" | translate}} : &nbsp;
              <span *ngIf="package_d?.commission=='Y'"> <i class="fas fa-check  text-success"></i></span>
              <span *ngIf="package_d?.commission=='N' "> <i class="fas fa-times  text-danger"></i></span>
            </h6>
          </div>


          <div class="col-md-6">
            <h6 class="font-weight-500">{{"Subuser" | translate}} :&nbsp;
              <span *ngIf="package_d?.sub_user=='Y'"><i class="fas fa-check  text-success"></i></span>
              <span *ngIf="package_d?.sub_user=='N' "><i class="fas fa-times  text-danger"></i></span>
            </h6>
          </div>
          <div class="col-md-6">
            <h6 class="font-weight-500">{{"Member" | translate}} :&nbsp;
              <span *ngIf="package_d?.member=='Y'"><i class="fas fa-check  text-success"></i></span>
              <span *ngIf="package_d?.member=='N' "><i class="fas fa-times  text-danger"></i></span>
            </h6>
          </div>

          <div class="col-md-6">
            <h6 class="font-weight-500">{{"Coupon" | translate}} :&nbsp;
              <span *ngIf="package_d?.coupen=='Y'"><i class="fas fa-check  text-success"></i></span>
              <span *ngIf="package_d?.coupen=='N' "><i class="fas fa-times  text-danger"></i></span>
            </h6>
          </div>
          <div class="col-md-6">
            <h6 class="font-weight-500">{{"Online Payment" | translate}} :&nbsp;
              <span *ngIf="package_d?.online_payment=='Y'"><i class="fas fa-check  text-success"></i></span>
              <span *ngIf="package_d?.online_payment=='N' "><i class="fas fa-times  text-danger"></i></span>
            </h6>
          </div>


          <div class="col-md-6">
            <h6 class="font-weight-500">{{"Shipping" | translate}}: &nbsp;
              <span *ngIf="package_d?.shipping=='Y'"><i class="fas fa-check  text-success"></i></span>
              <span *ngIf="package_d?.shipping=='N' "><i class="fas fa-times text-danger"></i></span>
            </h6>
          </div>
          <div class="col-md-6">
            <h6 class="font-weight-500">{{"Expiry Period" | translate}}:-
              <span *ngIf="package_d?.monthly_period==0 || package_d?.monthly_period=='0'">{{"Today" |
                translate}}</span>
              <span *ngIf="package_d?.monthly_period==-1 || package_d?.monthly_period=='-1'">{{"Unlimited" |
                translate}}</span>
              <span
                *ngIf="package_d?.monthly_period!=0 && package_d?.monthly_period!='0' && package_d?.monthly_period!=-1 && package_d?.monthly_period!='-1'">{{
                package_d?.monthly_period }} {{"Month" | translate}}</span>
            </h6>
          </div>

          <div class="col-md-6">
            <h6 class="font-weight-500">{{"Package Checkout" | translate}}: &nbsp;
              <span *ngIf="package_d?.allowed_checkout=='Y'"><i class="fas fa-check  text-success"></i></span>
              <span *ngIf="package_d?.allowed_checkout=='N' "><i class="fas fa-times text-danger"></i></span>
            </h6>
          </div>

        </div>



      </mat-card>


      <mat-card *ngIf="package_d" class="mt-3 mb-2">


        <div class="project-discription p-0 m-0" style="color: #b84371; 
  font-weight: 600; cursor: pointer;">
          <p class="row" (click)="opencoupen=!opencoupen" *ngIf="!opencoupen"><span class="col-md-10">{{"Apply coupon
              code" | translate}}?</span> <span class=" text-right col-md-2"><i class="fas fa-angle-up"></i></span> </p>
          <p class="row" *ngIf="opencoupen"><span class="col-md-10">{{"Enter coupon code" | translate}}</span> <span
              class=" text-right col-md-2" (click)="opencoupen=!opencoupen"><i class="fas fa-angle-down"></i></span></p>
        </div>
        <form [formGroup]="couponForm" *ngIf="opencoupen" class="p-0 m-0">

          <mat-form-field appearance="fill" class="" style="width: 75%;">
            <mat-label class="mat-label">{{"Enter coupon code" | translate}}</mat-label>
            <input class="text-left" matInput placeholder="Enter coupon code" formControlName="coupon_code" />
            <mat-icon matSuffix>view_stream</mat-icon>
          </mat-form-field> &nbsp;
          <button *ngIf="coupon_count==1" mat-raised-button class="" (click)="coupon_code(couponform)"
            [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor" type="submit" class="order-button "
            style="    position: relative;
          top: -5px;">
            {{"Apply" | translate}}
          </button>
          <button *ngIf="coupon_count==0" style="    position: relative;
          top: -5px;" mat-raised-button [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor"
            (click)="disab()" type="submit" class="order-button
             " [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor">
            {{"Remove" | translate}}
          </button>
          <br>
        </form>
      </mat-card>

      <mat-card *ngIf="package_d">
        <h6 class="font-weight-600 text-left product-border-heading" [style.color]="headingcolor"
          style="font-size: 16px;  padding-bottom: 5px;">&nbsp;{{"Price Review" | translate}} </h6>
        <div class="row justify-content-center" style="font-size: 14px!important; font-weight: 600; color: #000000a3;">
          <div class="col-lg-5">{{"Package Amount" | translate}} </div>
          <div class="col-lg-5">: &nbsp; {{myRecharge.controls.amount.value | currency:'INR'}}</div>

          <div class="col-lg-5">
            {{"Discount Amount" | translate}}
          </div>
          <div class="col-lg-5"> : &nbsp;
            {{myRecharge.controls.disc_price.value | currency:'INR'}}
          </div>

          <div class="col-lg-5">
            {{"Total Amount" | translate}}
          </div>
          <div class="col-lg-5"> : &nbsp;
            {{myRecharge.controls.total_amount.value | currency:'INR'}}
          </div>
        </div>

      </mat-card>
      <!-- end for total prices -->
      <mat-card class="mb-2 mt-2" *ngIf="package_d">
        <div class="row   justify-content-center  ">
          <div class="col-lg-11 product-border-heading" [style.color]="headingcolor" style="font-size: 16px;">
            {{"Payment Methods" | translate}}
          </div>

        </div>

        <div class="row justify-content-center ml-0 mt-2">
          <div class="col-lg-11">

            <mat-radio-group *ngIf="usertypeid=='1'" aria-label="Payment Modes">
              <div *ngFor="let option of paymentOptions; let i = index">
                <mat-radio-button style="font-size: 14px; font-weight: 600;"
                  *ngIf="option.payment_method_id>1 && option.payment_method_id>'1' && option.payment_method_id=='2'"
                  value="{{ option.payment_method_id }}" (click)="getPaymentDetails(option.payment_method_id)"
                  class="  ">
                  {{ option.name }}
                </mat-radio-button>
                <!-- <hr/> -->
                <mat-radio-button style="font-size: 14px;font-weight: 600;"
                  *ngIf="option.payment_method_id==1 && usertypeid=='1'" value="{{ option.payment_method_id }}"
                  (click)="getPaymentDetails(option.payment_method_id)" class=" ">
                  {{ option.name }}
                </mat-radio-button>

                <!-- <hr *ngIf="i != option.name.length > 1"> -->

              </div>


              <form *ngIf="this.myRecharge.controls.payment_method_id.value=='3'" #form method="post"
                action="https://sandboxsecure.payu.in/_payment" target="blank" name="payuForm">

                <input type="Hidden" name="service_provider" value="payu_paisa" size="64" />
                <input type="Hidden" name="amount" value={{fillPaymentForm.amount}} />

                <input type="Hidden" name="productinfo" value={{fillPaymentForm.productinfo}} />
                <input type="Hidden" name="firstname" id="name" value={{fillPaymentForm.firstname}} />
                <input type="Hidden" name="email" value={{fillPaymentForm.email}} />
                <input type="Hidden" name="phone" value={{fillPaymentForm.phone}} />
                <input type="Hidden" name="surl" value={{fillPaymentForm.surl}} size="64" />


                <input type="Hidden" name="udf1" value={{fillPaymentForm.udf1}} />
                <input type="Hidden" name="udf2" value={{fillPaymentForm.udf2}} />
                <input type="Hidden" name="udf3" value={{fillPaymentForm.udf3}} />
                <input type="Hidden" name="udf4" value={{fillPaymentForm.udf4}} />
                <input type="Hidden" name="udf5" value={{fillPaymentForm.udf5}} />



                <input type="Hidden" name="furl" value={{fillPaymentForm.furl}} size="64" />
                <input type="Hidden" name="curl" value={{fillPaymentForm.curl}} size="64" />
                <input type="Hidden" name="hash" value={{fillPaymentForm.hash}} />
                <input type="Hidden" name="key" value={{fillPaymentForm.key}} />

                <input type="Hidden" name="txnid" value={{fillPaymentForm.txnid}} />



              </form>

              <div>


                <form method="post" *ngIf="this.myRecharge.controls.payment_method_id.value=='4'" #form
                  action="https://securegw-stage.paytm.in/order/process" name="paytm" target="blank">

                  <table border="1">
                    <tbody>
                      <input type="hidden" name="MID" value={{fillPaytmForm.MID}}>

                      <input type="hidden" name="WEBSITE" value={{fillPaytmForm.WEBSITE}}>

                      <input type="hidden" name="ORDER_ID" value={{fillPaytmForm.ORDER_ID}}>

                      <input type="hidden" name="CUST_ID" value={{fillPaytmForm.CUST_ID}}>
                      <input type="hidden" name="MOBILE_NO" value={{fillPaytmForm.MOBILE_NO}}>
                      <input type="hidden" name="EMAIL" value={{fillPaytmForm.EMAIL}}>
                      <input type="hidden" name="INDUSTRY_TYPE_ID" value={{fillPaytmForm.INDUSTRY_TYPE_ID}}>
                      <input type="hidden" name="CHANNEL_ID" value={{fillPaytmForm.CHANNEL_ID}}>
                      <input type="hidden" name="TXN_AMOUNT" value={{fillPaytmForm.TXN_AMOUNT}}>
                      <input type="hidden" name="CALLBACK_URL" value={{fillPaytmForm.CALLBACK_URL}}>
                      <input type="hidden" name="CHECKSUMHASH" value={{fillPaytmForm.CHECKSUMHASH}}>
                    </tbody>
                  </table>


                </form>

              </div>
            </mat-radio-group>
            <!-- end payment methods -->
          </div>
        </div>
      </mat-card>
      <div class="col-md-12 text-center">
        <button
          *ngIf="(makePay==true && (this.myRecharge.controls.payment_method_id.value=='2' || this.myRecharge.controls.payment_method_id.value==2)) && myRecharge.controls.package_id!=''"
          mat-raised-button class="order-button   text {
                      font-size: 13px;
                      font-weight: 600!important;
                      font-family: sans-serif;
                    }" (click)="recharge()" [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor">
          {{"Pay Now" | translate}}
        </button>

        <button
          *ngIf="(makePay==true && (this.myRecharge.controls.payment_method_id.value=='1' || this.myRecharge.controls.payment_method_id.value==1)) && myRecharge.controls.package_id!=''"
          [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor" mat-raised-button
          class="order-button text " (click)="recharge()">
          {{"Recharge" | translate}}
        </button>
        <button *ngIf="(makePay==false) " mat-raised-button class="bg-secondary text-white text "
          style="cursor: not-allowed;">{{"Pay Now" | translate}} </button>

        <button (click)="form.submit(); navigateTo(this.razorpayId);check(form)" *ngIf="(makePay==true && (this.myRecharge.controls.payment_method_id.value=='3' 
    || this.myRecharge.controls.payment_method_id.value==3)) && myRecharge.controls.package_id!='' " mat-raised-button
          class="order-button  " [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor"
          type="submit">{{"Pay Now" | translate}}
          <!-- Place Order -->
        </button>



        <button id="rzp-button1" mat-raised-button class="order-button " [style.color]="buttontextcolor"
          [style.background-color]="buttonbgcolor" *ngIf="(makePay==true && (this.myRecharge.controls.payment_method_id.value=='4' 
    || this.myRecharge.controls.payment_method_id.value==4)) && myRecharge.controls.package_id!=''"
          (click)="form.submit(); navigateTo(this.razorpayId);" type="submit">{{"Pay Now" | translate}}
          <!-- Place Order -->

        </button> &nbsp;&nbsp; <button [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor"
          mat-raised-button class="order-button  mt-2 " (click)="action('fetch')">
          {{"Cancel" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- end for deducted amount modal -->
<!-- start for checkout status
   -->




<div *ngIf="loader">
  <div>
    <div class="text-center" style="padding: 1% 0;">
      <mat-card-content *ngIf="paymentStatus == 'Pending'">
        <img style="height:90px;width:185px;" src="{{loaderAsset}}">
        <h4 class="pt-3" style="font-weight:600;">{{"Waiting" | translate}}...!</h4>
        <h6>{{"Waiting For Response From Payment Gateway" | translate}}....</h6>
        <div class="example-button-row pt-4 ">
          <button mat-raised-button class=" order-button" [style.background-color]="buttonbgcolor"
            [style.color]="buttontextcolor" routerLink="/dashboard">{{"Back To Home" | translate}}</button>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="paymentStatus == 'Success'">
        <img style="height: 90px;width:195px;" src="assets/img/success.gif">
        <h4 class="pt-3" style="font-weight:600;">{{"Success.!" | translate}}</h4>


        <h6>{{"Recharge Done SuccessFully." | translate}}</h6>

        <div class="example-button-row pt-4 ">
          <button mat-raised-button class=" order-button" [style.background-color]="buttonbgcolor"
            [style.color]="buttontextcolor" routerLink="/dashboard">{{"Back To Home" | translate}}</button>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="paymentStatus == 'Failed'">
        <img src="https://img.icons8.com/officel/80/000000/high-importance.png" height="90" width="185" />
        <h4 class="pt-3" style="font-weight:600;">{{"Failed.!" | translate}}</h4>
        <h6>{{"The Recharge Failed." | translate}}</h6>
        <div class="example-button-row pt-4 ">
          <button mat-raised-button [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor"
            class="order-button " routerLink="/dashboard">{{"Back To Home" | translate}}</button>
        </div>
      </mat-card-content>
    </div>
  </div>

</div>

<!-- end for checkout status -->

<!-- Strat for withdraw-->
<!--  <div class="row mt-3">
      <div class="col-md-4">
       <h6 class="font-weight-600 ml-3">Total Amount: <i class="fas fa-rupee-sign"></i>{{this.sum_amount}}</h6>
     </div>
     <div class="col-md-4">
       
     </div> 
     <div class="col-md-4 text-right pr-4">
       <button
                mat-raised-button
                (click)="openXl(contentWithdraw)"
                matTooltip="Wallet Withdraw"
                class="text-right order-button text-white mr-3"
              >
                Wallet Withdraw
              </button>
           
     </div>
    </div>  -->