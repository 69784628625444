<mat-toolbar  class="example-toolbar shadow-sm" >
  <button mat-icon-button (click)="isExpanded = !isExpanded" class="d-md-block d-lg-block d-none">
    <i class="fas fa-sort-amount-down" (click)="menuvisible()" *ngIf="visible"></i>
    <mat-icon (click)="menuvisible()" *ngIf="!visible">menu_open</mat-icon>
  </button>
  <h1 class="example-app-name d-flex d-md-block d-lg-block d-none" *ngIf="vendor">
  <div style="position: relative; left: 1%;"
    class="mr-auto ml-auto d-none d-md-block d-lg-block d-xl-block"
    *ngIf="vendor">
  <img src="{{logo}}" height="35" *ngIf="ecomtrails" (error)="onImgError($event)">
  <img src="{{logo}}" height="35" *ngIf="maschile">
  </div>
    <span
      *ngIf="userdata?.icon_image_link == null || userdata?.icon_image_link == ''">
      <p *ngIf="flag == 0" class="pl-2 pt-2">
        {{ userdata.display_name }}
      </p>
      <p *ngIf="flag == 1" class="pl-2 pt-2">
        {{ userDataNew.display_name }}
      </p>
    </span>
  </h1>
  <h1
    class="example-app-name d-md-block d-lg-block d-none"
    *ngIf="controller || vendornocompregistered || vendornoverified">
    <img
      src="{{favicon}}"
      class="d-block d-md-none d-lg-none d-xl-none "
      height="35" *ngIf="ecomtrails"/>
    <!-- for emall start -->
    <img
    src="{{logo}}"
    height="35"
    class="d-none d-md-block d-lg-block d-xl-block" *ngIf="ecomtrails " />
    <img
    src="assets/img/logo-29toys.png"
    class="d-block d-md-none d-lg-none d-xl-none "
    height="35"  *ngIf="ninetoys"/>
    <img
    src="assets/img/logo-29toys.png"
    height="35"
    class="d-none d-md-block d-lg-block d-xl-block" *ngIf="ninetoys"/>
  </h1>


  <!-- <h1
  class="example-app-name d-md-block d-lg-block d-none"
  *ngIf="opm || bkp || catm">
  <img
  src="{{userdata?.icon_image_link}}"
  height="35"
  class="d-none d-md-block d-lg-block d-xl-block" />
</h1> -->



 
<div class="ml-auto d-flex pt-2 ">
<div *ngIf="comp_num && comp_num!='-1' && usertypeid=='1'">
  <form [formGroup]="modes">
    <mat-slide-toggle   [checked]="modes.controls.modeC.value=='true'?true:false"    
     (change)="mode($event.checked)" [disabled]="web_publish==false && ecomtrails==true"
      formControlName="modeC" style="font-size: 13px;">{{"Live Mode"|translate}}</mat-slide-toggle>
</form>
</div>

<div *ngIf="bkp || opm || catm || (usertypeid!='7' && (vendor || vendornoverified))" class="mr-2">
  <a *ngIf="(comp_num=='0' && ecomtrails==false) || ecomtrails==true" 
   class=" cursor-pointer d-flex " style="font-size: 12px;
   color: #212121;
   font-weight: 600;
   text-decoration: none;
   margin-left: 26px;
   margin-right: 17px;"
   matTooltip="Preview Your Store"
    routerLink="/preview-mode">
 <i class="far fa-eye" style="margin-top: 8px;
 color: #c04a53;
 font-size: 16px;"></i>&nbsp;{{"Store Preview"|translate}}</a>
</div>

    <a class="mr-2 ml-2">
      <div class="pmd-dropdown d-md-block d-lg-block d-none">
        <div
          class="  dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false">
        <p class="d-flex cursor-pointer text-info vendor-profile">
          <i class="fas fa-user" style="position: relative; top: 5px;color: #c04a53;"></i></p>
        </div>

        <div class="dropdown-menu shadow">
          <div class="container-fluid">
          <div class="row mb-3" *ngIf="(vendor || vendornoverified || usertypeid=='1')">
            <div class="col-lg-3">
              <img
              *ngIf="userdata?.icon_image_link != null || userdata?.icon_image_link != ''"
              src="{{ userdata?.icon_image_link }}"
              class="d-block d-md-none d-lg-none d-xl-none "
              height="50"/>
            <img
              *ngIf="userdata?.icon_image_link != null || userdata?.icon_image_link != ''"
              src="{{ userdata?.icon_image_link }}"
              height="50" width="60"
              class="d-none d-md-block d-lg-block d-xl-block"/> 
            </div>
            <div class="col-lg-7" style="overflow: hidden;">
              <h6 class="" style="font-size: 13px;
              text-transform: uppercase; width: 100%;
              white-space: pre-line;
              font-weight: 600;
              line-height: 16px;
              padding-top: 7px;">
               {{userdata?.display_name}} 
              </h6>
            </div>
            <div class="col-lg-1 cursor-pointer">
              <i class="fas fa-cog" style="color: #881852;"  routerLink="/company-profile"></i>
            </div>
          </div>
          <div class="row bg-light pb-3">
            <div class="col-lg-12 pt-1 mb-0"><p style="font-size: 13px;
              font-weight: 600;">{{"Logged in as"|translate}}  <span *ngIf="opm">( Operational Manager )</span>
              <span *ngIf="catm">( Catalog Manager )</span> </p></div>
            <div class="col-lg-10 mt-0" style="font-size: 12px; overflow: hidden;" 
            *ngIf="(vendor || vendornoverified || usertypeid=='2')|| opm || catm">
              <i class="far fa-user-circle fa-2x"></i>&nbsp;<!--<span *ngIf="userdetails?.email !=null" style="position: relative;
              top: -5px;
              left: 1px;">{{userdetails?.email}}</span> <span *ngIf="userdetails?.email ==null" style="position: relative;
             top: -5px;
             left: 1px;">{{userdetails?.mobile}}</span>--> 

             <span style="position: relative;
             top: -5px;">
              {{userdetails?.email?userdetails?.email:userdetails?.mobile}} 
             </span>
            </div> 
            <div class="col-lg-2" *ngIf="(vendor || vendornoverified || usertypeid=='2')">
              <i class="fas fa-cog cursor-pointer" style="color: #881852" routerLink="/user-profile"></i>
            </div>
            <div class="col-lg-12 mt-3">
              <button class=" order-button w-100" [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor"  matTooltip="logout"
              (click)="logout()" mat-raised-button>{{"Log out"|translate}}</button>
            </div>
          </div>
        </div>
        
        <div *ngIf="(vendor || vendornoverified)" >
          <div *ngIf=" (usertypeid!='7' && (vendor || vendornoverified ))"> 
            <a *ngIf="(comp_num=='0' && ecomtrails==false) || ecomtrails==true ||usertypeid==1 "
              class="dropdown-item cursor-pointer d-flex "
              matTooltip="Settings"
              [routerLinkActive]="['is-active']" routerLink="/company-settings"
            >
            <i class="fas fa-cog pt-2  pr-2" style="font-size: 14px;" *ngIf="isExpanded || isShowing"></i>
             &nbsp;  {{"Settings"|translate}}</a
            > 
          </div>
        </div>
        <div *ngIf="controller" >
          <div *ngIf="(usertypeid=='2' && controller)"> 
            <a 
              class="dropdown-item cursor-pointer d-flex "
              matTooltip="Settings"
              [routerLinkActive]="['is-active']" routerLink="/controller-settings"
            >
            <i class="fas fa-cog pt-2  pr-2" style="font-size: 14px;" *ngIf="isExpanded || isShowing"></i>
             &nbsp;  {{"Settings"|translate}}</a
            > 
          </div>
        </div>
        </div>
      </div>
    </a>
  </div>
</mat-toolbar>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 [style.color]="headingcolor" class="modal-title" style="color:red">{{"Warning"|translate}}</h5>

    <i class="fas fa-times cursor-pointer" (click)="modal.dismiss('Cross click')" 
      ></i
    >
  </div>
  <div class="modal-body">
    <div class="col-sm-12">
      <div>
        <div>
          <div>
            <form [formGroup]="registerForm">
              <div class="row">
                <p style="font-weight: 100px">{{"Your company profile is incomplete please upload company profile image for better experience"|translate}}.</p>
               
              </div>

              <mat-card-actions
                class="text-center down"
                style="margin-top: 20px;"
              >
                <div>
                  <button
                    class="ml-auto mr-auto btn order-button " [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor"
                    (click)="onRoute(); modal.close('Save click')"
                    mat-raised-button
                  >
                    {{"Upload"|translate}}
                  </button>&nbsp;&nbsp;
                  <button
                    class="ml-auto mr-auto btn order-button " [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor"
                    (click)="modal.dismiss('Cross click')"
                    mat-raised-button
                  >
                    {{"Close"|translate}}
                  </button>
                </div>
              </mat-card-actions>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<mat-sidenav-container class="example-container mt-5 d-none d-md-block" autosize  >
  <mat-sidenav
    
    #sidenav class="example-sidenav shadow" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" >
    
<!-- ********************************Start Vendor************** -->
    <mat-nav-list *ngIf="vendor">
      <mat-list-item class="cursor-pointer "  [routerLinkActive]="['is-active']"  routerLink="/dashboard">
        <img
          src="assets/img/sidemenu/home.png"
          height="30"
          width="30"
          matTooltip="Dashboard"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing" 
          >{{"Dashboard"|translate}}</span
        >
      </mat-list-item>
      
      <mat-list-item class="cursor-pointer"  [routerLinkActive]="['is-active']" >
        <div routerLink="/order-vendor-new">
        <img
          src="assets/img/sidemenu/orders.png"
          height="30"
          width="30"
          matTooltip="Orders"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Orders"|translate}}</span></div>
        <mat-icon class="menu-button" (click)="showSubmenu = !showSubmenu" [ngClass]="{'rotated' : showSubmenu}" *ngIf="(isExpanded || isShowing) && !return_policy">expand_more</mat-icon>
        
      </mat-list-item>
      <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="(isShowing || isExpanded) && (!return_policy && usertypeid!='7')">
				<mat-list-item [routerLinkActive]="['is-active']" routerLink="/return-orders">
          <img
          src="assets/img/sidemenu/return-orders.png"
          height="30"
          width="30"
          matTooltip="Return Orders"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Return Orders"|translate}}</span>
          
          </mat-list-item>
				<mat-list-item [routerLinkActive]="['is-active']" routerLink="/order-refunds">
          
          <img
          src="assets/img/sidemenu/refund-orders.png"
          height="30"
          width="30"
          matTooltip="Return Orders"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Refund Orders"|translate}}</span>
          </mat-list-item>
				<!-- <mat-list-item (click)="showSubSubMenu = !showSubSubMenu" class="parent">
					<span class="full-width" *ngIf="isExpanded || isShowing">Nested Menu</span>
					<mat-icon class="menu-button" [ngClass]="{'rotated' : showSubSubMenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
				</mat-list-item>
				<div class="submenu" [ngClass]="{'expanded' : showSubSubMenu}" *ngIf="isShowing || isExpanded">
					<mat-list-item>SubSubmenu Item 1</mat-list-item>
					<mat-list-item>SubSubmenu Item 2</mat-list-item>
				</div> -->
			</div>
<div *ngIf="usertypeid!='7'">
     
      <mat-list-item class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/list-product">
        <img
          src="assets/img/sidemenu/product-s.png"
          height="30"
          width="30"
          matTooltip="Products"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Products"|translate}}</span>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/list-services">
        <img
          src="assets/img/sidemenu/size-chart.png"
          height="30"
          width="30"
          matTooltip="Services"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Services"|translate}}</span>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/view-category">
        <img
          src="assets/img/sidemenu/category.png"
          height="30"
          width="30"
          matTooltip="Category"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Category"|translate}}</span>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/view-brands">
        <img
          src="assets/img/sidemenu/brands.png"
          height="30"
          width="30"
          matTooltip="Brands"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Brands"|translate}}</span>
      </mat-list-item>

      <mat-list-item *ngIf="is_courier && (project=='ecomtrails' || project=='local') && shipping==true && functionality==true"
       class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/shipping-tab">
        <img
          src="assets/img/sidemenu/product-s.png"
          height="30"
          width="30"
          matTooltip="Shipping"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Shipping"|translate}} </span
        >
      </mat-list-item>
      <mat-list-item *ngIf="functionality==true && sub_user==true" class="cursor-pointer "
       [routerLinkActive]="['is-active']" routerLink="/view-users">
        <img src="assets/img/sidemenu/customers.png"  height="30"
        width="30"
        matTooltip="Add & View Users"/>
       &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Users"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item *ngIf=" cust_reg_enable && functionality==true && member==true" class="cursor-pointer "
       [routerLinkActive]="['is-active']"  routerLink="/customer-verification">
        <img
          src="assets/img/sidemenu/members.png"
          height="30"
          width="30"
          matTooltip="Members"
        />&nbsp; &nbsp;

        
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Members"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item *ngIf="((usertypeid!='7' && courier_serv=='8') && (vendornoverified || vendor))" class="cursor-pointer "
       [routerLinkActive]="['is-active']"  routerLink="/depo-request">
        <img
          src="https://img.icons8.com/cute-clipart/64/000000/home.png"
          height="30"
          width="30"
          matTooltip="Depos"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Depos"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item *ngIf="vendornoverified || vendor && customer_chat=='1'" class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/user_to_admin_communications">
        <img
          src="assets/img/sidemenu/user-communication.png"
          height="30"
          width="30"
          matTooltip="user connect"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"User-Communication"|translate}}<sup *ngIf="msgData>0" (counter)="fetchData($event)">{{msgData}}</sup></span
        >
      </mat-list-item>
      <mat-list-item *ngIf="vendornoverified || vendor && customer_cart=='1'" class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/customer-cart">
        <img
          src="https://img.icons8.com/external-bearicons-flat-bearicons/64/000000/external-add-to-cart-essential-collection-bearicons-flat-bearicons.png"
          height="30"
          width="30"
          matTooltip="cart"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Customer Cart"|translate}}</span
        >
      </mat-list-item>
     
  <mat-list-item *ngIf=" cust_reg_enable && member_wallet && functionality==true && member==true" class="cursor-pointer " 
  [routerLinkActive]="['is-active']"  routerLink="/member-wallet-admin">
        <img
          src="assets/img/sidemenu/member-wallet.png"
          height="30"
          width="30"
          matTooltip="Members Wallet"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Members Wallet"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item  class="cursor-pointer " *ngIf="pinboard=='1'" [routerLinkActive]="['is-active']"  routerLink="/broadcast">
        <img
          src="assets/img/sidemenu/broadcast.png"
          height="30"
          width="30"
          matTooltip="Broadcast"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Broadcast"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item *ngIf="pinboard=='1'" class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/noticeboard">
        <img
          src="assets/img/sidemenu/pinboard.png"
          height="30"
          width="30"
          matTooltip="Notice Board"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Pin board"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item *ngIf="pinboard=='1'" class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/company-faq">
        <img
          src="assets/img/sidemenu/faqs.png"
          height="30"
          width="30"
          matTooltip="Company FAQs"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"FAQs"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item  class="cursor-pointer " *ngIf="customer_chat=='1'" [routerLinkActive]="['is-active']"  routerLink="/wall-post">
        <img
          src="https://img.icons8.com/bubbles/100/000000/email--v2.png"
          height="30"
          width="30"
          matTooltip="Wall Topic"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Wall Topic"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item  *ngIf="size_chart=='1'" class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/admin-size-chart">
        <img
          src="assets/img/sidemenu/size-chart.png"
          height="30"
          width="30"
          matTooltip="Broadcast"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Size Chart"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item *ngIf="group_buying" class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/Group-buying">
        <img
          src="https://img.icons8.com/bubbles/100/000000/email--v2.png"
          height="30"
          width="30"
          matTooltip="Broadcast"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Group Buying"|translate}}</span
        >
      </mat-list-item>

      <!-- work pending  -->
      <mat-list-item  class="cursor-pointer " *ngIf="show_testimonials" 
      [routerLinkActive]="['is-active']"  routerLink="/admin-testimonials">
        <img
          src="https://img.icons8.com/fluent/96/000000/quote.png"
          height="30"
          width="30"
          matTooltip="Testimonials"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Testimonials"|translate}}</span
        >
      </mat-list-item>
    
<!-- end member wallet -->
      <!-- non-registered customers -->
      <mat-list-item *ngIf="(comp_num=='0' && ecomtrails==false) || ecomtrails==true" class="cursor-pointer "
       [routerLinkActive]="['is-active']" routerLink="/non-registered-customer">
        <img src="https://img.icons8.com/bubbles/50/000000/user.png"  height="30"
        width="30"
        matTooltip="Customers"/>
       &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Customers"|translate}}</span
        >
      </mat-list-item>
       <mat-list-item *ngIf="((comp_num=='0' && ecomtrails==false) || ecomtrails==true) && functionality==true && sub_user==true" 
       class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/coupons">
        <img src="https://img.icons8.com/cute-clipart/64/000000/cutting-coupon.png" height="30"
        width="30"
        matTooltip="Coupons"/>
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Coupons"|translate}}</span>
      </mat-list-item>
    
      <mat-list-item *ngIf="(comp_num=='0' && ecomtrails==false) || ecomtrails==true" class="cursor-pointer" 
      [routerLinkActive]="['is-active']" routerLink="/bannertierwise">
        <img src="assets/img/sidemenu/banners.png" height="30"
        width="30"
        matTooltip="Sliders & banners"/>
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Banners"|translate}}</span
        > 
      </mat-list-item>

      <!-- start report -->
      <mat-list-item class="cursor-pointer " [routerLinkActive]="['is-active']" routerLink="/dashboard-reports">
        <img src="assets/img/sidemenu/reports.png"   height="30"
        width="30"
        matTooltip="Reports"/>
      &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Reports"|translate}}</span
        >
      </mat-list-item>
     <!-- start report -->

      <!-- start catalogue -->
       <mat-list-item  class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/catalogue-share">
        <img
          src="assets/img/sidemenu/catalouge.png"
          height="30"
          width="30"
          matTooltip="Catalogue"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Catalogue"|translate}}</span
        >
      </mat-list-item>
      <!-- end catalogue -->
    </div>

    </mat-nav-list>
<!-- ********************************End Vendor************** -->

<!-- *********************vendorcompanyotregistered*********************************** -->
    <mat-nav-list *ngIf="vendornocompregistered">
      <mat-list-item class="cursor-pointer " [routerLinkActive]="['is-active']" routerLink="/user-profile">
        <img
          src="assets/img/sidemenu/members.png"
          height="30"
          width="30"
          matTooltip="Profile"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Profile"|translate}}</span>
      </mat-list-item>
  
      <mat-list-item class="cursor-pointer" *ngIf="project=='ecomtrails'" [routerLinkActive]="['is-active']" 
      routerLink="/quick-company-addition">
        <img
          src="https://img.icons8.com/color/48/000000/new-company.png"
          height="30"
          width="30"
          matTooltip="Add Company"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Add Company"|translate}}</span
        >
      </mat-list-item>
     
    </mat-nav-list>
<!-- ************************venodrnotverified**************** -->

<!-- ********************************registerd customer************** -->
<mat-nav-list >
</mat-nav-list>
    <mat-nav-list *ngIf="vendornoverified ">
      <mat-list-item class="cursor-pointer " [routerLinkActive]="['is-active']" routerLink="/dashboard">
        <img
          src="assets/img/sidemenu/home.png"
          height="30"
          width="30"
          matTooltip="Dashboard"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Dashboard"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/order-vendor-new">
        <img
          src="assets/img/sidemenu/orders.png"
          height="30"
          width="30"
          matTooltip="Orders"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Orders"|translate}}</span>
      </mat-list-item>

      <div *ngIf="usertypeid!='7'">
      <mat-list-item class="cursor-pointer" *ngIf="!return_policy" routerLink="/return-orders" [routerLinkActive]="['is-active']">
        <img
          src="assets/img/sidemenu/return-orders.png"
          height="30"
          width="30"
          matTooltip="Return Orders"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Return Orders"|translate}}</span>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/list-product">
        <img
          src="assets/img/sidemenu/product-s.png"
          height="30"
          width="30"
          matTooltip="Products"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Products"|translate}}</span>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/view-category">
        <img
          src="assets/img/sidemenu/category.png"
          height="30"
          width="30"
          matTooltip="Category"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Category"|translate}}</span>
      </mat-list-item>

      <mat-list-item class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/view-brands">
        <img
          src="assets/img/sidemenu/brands.png"
          height="30"
          width="30"
          matTooltip="Brands"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Brands"|translate}}</span>
      </mat-list-item>
        <mat-list-item *ngIf="(is_courier && (project=='ecomtrails' || project=='local')) && functionality==true && shipping==true" 
        [routerLinkActive]="['is-active']" routerLink="/shipping-tab" class="cursor-pointer">
        <img
          src="assets/img/sidemenu/shipping.png"
          height="30"
          width="30"
          matTooltip="Shipping"/>
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Shipping"|translate}} </span>
      </mat-list-item>
   
        
      <mat-list-item *ngIf="functionality==true && sub_user==true" class="cursor-pointer " [routerLinkActive]="['is-active']"
       routerLink="/view-users">
        <img src="assets/img/sidemenu/members.png"  height="30"
        width="30"
        matTooltip="Add & View Users"/>
       &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Users"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item *ngIf=" cust_reg_enable && functionality==true && member==true" class="cursor-pointer " 
      [routerLinkActive]="['is-active']"  routerLink="/customer-verification">
        <img
          src="assets/img/sidemenu/members.png"
          height="30"
          width="30"
          matTooltip="Members"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Members"|translate}}</span
        >
      </mat-list-item>
         
<!-- start member wallet -->
  <mat-list-item *ngIf=" cust_reg_enable && member_wallet && functionality==true && member==true" class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/member-wallet-admin">
        <img
          src="assets/img/sidemenu/member-wallet.png"
          height="30"
          width="30"
          matTooltip="Members Wallet"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Members Wallet"|translate}}</span
        >
      </mat-list-item>
      
<!-- end member wallet -->
      <!-- non-registered customers -->
       <mat-list-item *ngIf="(comp_num=='0' && ecomtrails==false) || ecomtrails==true" class="cursor-pointer " [routerLinkActive]="['is-active']" routerLink="/non-registered-customer">
        <img src="assets/img/sidemenu/customers.png"  height="30"
        width="30"
        matTooltip="Customers"/>
       &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Customers"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item *ngIf="((comp_num=='0' && ecomtrails==false) || ecomtrails==true) && functionality==true && coupon==true" class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/coupons">
    
        <img src="assets/img/sidemenu/coupons.png" height="30"
        width="30"
        matTooltip="Coupons"/>
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Coupons"|translate}}</span>
      </mat-list-item>
     
       <!-- start catalogue -->
       <mat-list-item  class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/catalogue-share">
        <img
          src="assets/img/sidemenu/catalouge.png"
          height="30"
          width="30"
          matTooltip="Catalogue"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Catalogue"|translate}}</span
        >
      </mat-list-item>
      <!-- end catalogue -->
    </div>
    </mat-nav-list>

    <!--***************************************controller******************************* -->
    <mat-nav-list *ngIf="controller">
      <mat-list-item class="cursor-pointer " [routerLinkActive]="['is-active']" routerLink="/dashboard">
        <img
          src="assets/img/sidemenu/home.png"
          height="30"
          width="30"
          matTooltip="Dashboard"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Dashboard"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item
        class="cursor-pointer"
        routerLink="/view-company-list" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/color/48/000000/new-company.png"
          height="30"
          width="30"
          matTooltip="Comapny"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Company"|translate}}</span>
      </mat-list-item>
      <!-- start for rotes in emall Priyangee -->
 <mat-list-item  *ngIf=" (project=='emall' || project=='local')"
        class="cursor-pointer"
        routerLink="/host-routes" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/color/48/000000/new-company.png"
          height="30"
          width="30"
          matTooltip="Routes"/>
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Routes"|translate}}</span>
      </mat-list-item>
      <!-- end for routes in emall Priyangee -->
      <mat-list-item class="cursor-pointer" routerLink="/view-tax" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/doodle/48/000000/tax.png"
          height="30"
          width="30"
          matTooltip="Tax"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Tax"|translate}}</span>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/view-tags" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/nolan/64/update-tag.png"
          height="30"
          width="30"
          matTooltip="Tags"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Tags"|translate}}</span>
      </mat-list-item>
      <!-- company registry parameters tab by pankaj 17/3/2022-->
      <mat-list-item class="cursor-pointer" routerLink="/company-registry" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/color/48/000000/new-company.png"
          height="30"
          width="30"
          matTooltip="company-registry"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Company Registry"|translate}}</span>
      </mat-list-item>
      <!-- start package -->
       <mat-list-item *ngIf="project=='ecomtrails'" class="cursor-pointer" [routerLinkActive]="['is-active']"
        routerLink="/package">
        <img
          src="https://img.icons8.com/nolan/64/order-history.png"
          height="30"
          width="30"
          matTooltip="Package"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Package"|translate}}</span>
      </mat-list-item>
  <!-- end package -->
  <!-- start package coupon -->
       <mat-list-item *ngIf="project=='ecomtrails'" class="cursor-pointer" [routerLinkActive]="['is-active']" 
       routerLink="/package-coupons">
        <img
          src="https://img.icons8.com/nolan/64/order-history.png"
          height="30"
          width="30"
          matTooltip="Package Coupon"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Package Coupon"|translate}}</span>
      </mat-list-item>
  <!-- end package coupon-->
      <mat-list-item class="cursor-pointer" routerLink="/view-brands" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/ultraviolet/64/000000/bag-brand.png"
          height="30"
          width="30"
          matTooltip="Brands"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Brands"|translate}}</span>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/view-category" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/nolan/64/opened-folder.png"
          height="30"
          width="30"
          matTooltip="Category"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Category"|translate}}</span>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/list-product" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/nolan/64/used-product.png"
          height="30"
          width="30"
          matTooltip="Products"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Products"|translate}}</span>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" routerLink="/Greating-us" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/doodle/48/000000/tax.png"
          height="30"
          width="30"
          matTooltip="Greating"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Greating"|translate}}</span>
      </mat-list-item>
    </mat-nav-list>

    <!-- ********************************operational manager ********************** -->
    <mat-nav-list *ngIf="opm">
      <mat-list-item class="cursor-pointer" routerLink="/order-vendor-new" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/nolan/64/order-history.png"
          height="30"
          width="30"
          matTooltip="Orders"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Orders"|translate}}</span>
      </mat-list-item>
      <mat-list-item class="cursor-pointer" *ngIf="!return_policy" routerLink="/return-orders" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/nolan/64/order-history.png"
          height="30"
          width="30"
          matTooltip="Return Orders"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Return Orders"|translate}}</span>
      </mat-list-item>

      <mat-list-item class="cursor-pointer" routerLink="/order-refunds" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/nolan/64/order-history.png"
          height="30"
          width="30"
          matTooltip="Refunds Orders"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Refunds Orders"|translate}}</span>
      </mat-list-item>

      <mat-list-item class="cursor-pointer" routerLink="/list-product" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/nolan/64/used-product.png"
          height="30"
          width="30"
          matTooltip="Products"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Products"|translate}}</span>
      </mat-list-item>


      <mat-list-item   class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/admin-size-chart">
        <img
          src="https://img.icons8.com/bubbles/100/000000/email--v2.png"
          height="30"
          width="30"
          matTooltip="Broadcast"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Size Chart"|translate}}</span
        >
      </mat-list-item>

      <mat-list-item class="cursor-pointer" [routerLinkActive]="['is-active']" routerLink="/view-brands">
        <img
          src="https://img.icons8.com/color/48/000000/azure-1.png"
          height="30"
          width="30"
          matTooltip="Brands"
        />
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Brands"|translate}}</span>
      </mat-list-item>
      
      <mat-list-item  class="cursor-pointer "
      [routerLinkActive]="['is-active']" routerLink="/non-registered-customer">
       <img src="https://img.icons8.com/bubbles/50/000000/user.png"  height="30"
       width="30"
       matTooltip="Customers"/>
      &nbsp; &nbsp;
       <span class="full-width" *ngIf="isExpanded || isShowing"
         >{{"Customers"|translate}}</span
       >
     </mat-list-item>
     <!--<mat-list-item *ngIf="(comp_num=='0' && ecomtrails==false) || ecomtrails==true" class="cursor-pointer" -->
      <mat-list-item class="cursor-pointer" 
      routerLink="/coupons" [routerLinkActive]="['is-active']">
        <img src="https://img.icons8.com/cute-clipart/64/000000/cutting-coupon.png" height="30"
        width="30"
        matTooltip="Coupons"/>
        &nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing">{{"Coupons"|translate}}</span>
      </mat-list-item>
     
    </mat-nav-list>

    <!-- ************************catolog manager******************************-->
    <mat-nav-list *ngIf="catm">
      
      <mat-list-item class="cursor-pointer" routerLink="/list-product" [routerLinkActive]="['is-active']">
        <img
          src="https://img.icons8.com/nolan/64/used-product.png"
          height="30"
          width="30"
          matTooltip="Products"
        />
        &nbsp; &nbsp;
        <span class="full-width">{{"Products"|translate}}</span>
      </mat-list-item>
      <mat-list-item   class="cursor-pointer " [routerLinkActive]="['is-active']"  routerLink="/admin-size-chart">
        <img
          src="https://img.icons8.com/bubbles/100/000000/email--v2.png"
          height="30"
          width="30"
          matTooltip="Broadcast"
        />&nbsp; &nbsp;
        <span class="full-width" *ngIf="isExpanded || isShowing"
          >{{"Size Chart"|translate}}</span
        >
      </mat-list-item>
    </mat-nav-list>
   
       <!-- ***************************Book kepper************************** -->
       <mat-nav-list *ngIf="bkp">
        <mat-list-item class="cursor-pointer " routerLink="/dashboard" [routerLinkActive]="['is-active']">
          <img
            src="https://img.icons8.com/cute-clipart/64/000000/home.png"
            height="30"
            width="30"
            matTooltip="Dashboard"
          />&nbsp; &nbsp;
          <span class="full-width" *ngIf="isExpanded || isShowing"
            >{{"Dashboard"|translate}}</span
          >
        </mat-list-item>
        <mat-list-item class="cursor-pointer " routerLink="/dashboard-reports"  [routerLinkActive]="['is-active']">
          <img src="https://img.icons8.com/fluent/96/000000/graph-report-script.png"   height="30"
          width="30"
          matTooltip="Reports"/>
        &nbsp; &nbsp;
          <span class="full-width" *ngIf="isExpanded || isShowing"
            >{{"Reports"|translate}}</span
          >
        </mat-list-item>
    </mat-nav-list>

    <!-- listApp -->
    <mat-nav-list *ngIf="ListApp" class="mb-5"> 
      <h6 class="border-top border-bottom  pt-1 pb-1 pl-3 font-weight-600 " style="color: #424F63!important;"><img src="assets/img/logolistapp.png" height="30" style="border-radius: 50%"> &nbsp;List App</h6>
      <mat-list-item class="cursor-pointer " routerLink="/ListApp" >
        <img
          src="https://img.icons8.com/cute-clipart/64/000000/home.png"
          height="30"
          width="30"
          matTooltip="Dashboard"
        />&nbsp; &nbsp;
        <span class="full-width" style="color: #424F63!important;" *ngIf="isExpanded || isShowing"
          >{{"Dash board"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item class="cursor-pointer " routerLink="/ListApp/_manage_companies" >
        <img
        src="https://img.icons8.com/color/48/000000/new-company.png"
        height="30"
        width="30"
        matTooltip="Manage Comapny"
      />&nbsp; &nbsp;
        <span class="full-width" style="color: #424F63!important;" *ngIf="isExpanded || isShowing"
          >{{"Company Information"|translate}}</span
        >
      </mat-list-item>
      
      <mat-list-item class="cursor-pointer " routerLink="/ListApp/_manage_products" >
        <img
          src="https://img.icons8.com/nolan/64/used-product.png"
          height="30"
          width="30"
          matTooltip="Manage Products"
        />&nbsp; &nbsp;
        <span class="full-width" style="color: #424F63!important;" *ngIf="isExpanded || isShowing"
          >{{"Manage Products"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item class="cursor-pointer " routerLink="/ListApp/_manage_retailer" >
        <img
          src="https://img.icons8.com/color/48/000000/azure-1.png"
          height="30"
          width="30"
          matTooltip="Manage Retailer"
        />&nbsp; &nbsp;
        <span class="full-width" style="color: #424F63!important;" *ngIf="isExpanded || isShowing"
          >{{"Manage Retailer"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item class="cursor-pointer " routerLink="/ListApp/_manage_supplier" >
        <img src="https://img.icons8.com/bubbles/50/000000/user.png"  height="30"
        width="30"
        matTooltip="Manage Supplier"/>&nbsp; &nbsp;
        <span class="full-width" style="color: #424F63!important;" *ngIf="isExpanded || isShowing"
          >{{"Manage Supplier"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item class="cursor-pointer " routerLink="/ListApp/_manage_logs" >
        <img src="https://img.icons8.com/bubbles/50/000000/user.png"  height="30"
        width="30"
        matTooltip="Manage Logs"/>&nbsp; &nbsp;
        <span class="full-width" style="color: #424F63!important;" *ngIf="isExpanded || isShowing"
          >{{"Manage Logs"|translate}}</span
        >
      </mat-list-item>
      <mat-list-item class="cursor-pointer " routerLink="/ListApp/_manage_status" >
        <img src="https://img.icons8.com/bubbles/50/000000/user.png"  height="30"
        width="30"
        matTooltip="Manage Status"/>&nbsp; &nbsp;
        <span class="full-width" style="color: #424F63!important;" *ngIf="isExpanded || isShowing"
          >{{"Manage Status"|translate}}</span
        >
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <div class="example-sidenav-content mt-5" color="warning">
    <div class="main mt-3 ">
      <router-outlet></router-outlet>
    </div>
  </div>

</mat-sidenav-container>
<div style="width: 100%; height: 100%; padding: 30px; margin-top: 10%;  text-align: center;"
 class="d-block  d-lg-none d-md-none">
<p class="product-heading-app">{{"Welcome"|translate}} </p>

</div>

