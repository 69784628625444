<div class="container-fluid">

<div
  *ngIf="showLoader"
  class="row justify-content-center align-self-center"
  style="margin-top: 20vh;"
>
  <div *ngIf="showLoader" style="position: fixed;">
    <img src="{{loaderAsset}}" width="300px" height="300px" />
  </div>
</div>
<div *ngIf="!showLoader" class="">
  <div *ngIf="!visible">
    <mat-card>
      <form [formGroup]="couponForm">
        <div class="row">
          <div class="col-md-12 ">
            <div
              class="row pb-3"
              
            >
              <mat-card-subtitle
                style=" position: relative;top: 7px;"
              >
                <h4 class="tab-head">
                  <span class=" text-uppercase" [style.color]="headingcolor">{{"Add Coupons" | translate}}</span>
                </h4>
              </mat-card-subtitle>
            </div>
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="font-family: 'Titillium web',sans-serif;"
            >
              <mat-label>{{"Coupon Description" | translate}}</mat-label>
              <input
                type="text"
                matInput
                placeholder="Coupon Description"
                formControlName="description"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
        </div>

        <!-- ================ ROW-2 ================= -->
        <div class="row">
          <div class="col-md-4">
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="font-family: 'Titillium web',sans-serif;"
            >
              <mat-label>{{"Discount in Percent" | translate}} %</mat-label>
              <input
                type="number"
                matInput
                placeholder="Discount in Percent %"
                formControlName="discount_percent"
                class="unstyled"
                min="0"
                required
              />
              <mat-error>invalid number</mat-error>
            </mat-form-field>
          </div>
          <!-- ================================================ -->
          <div class="col-md-4">
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="font-family: 'Titillium web',sans-serif;"
            >
              <mat-label>{{"Maximum Discount" | translate}}(<i class="fas fa-rupee-sign"></i>)</mat-label>
              <input
                type="number"
                matInput
                placeholder="Maximum Discount"
                formControlName="max_disc"
                class="unstyled"
                min="0"
                required
              />
              <mat-error>invalid number</mat-error>
            </mat-form-field>
          </div>
          <!-- ======================================= -->

          <div class="col-md-4">
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="font-family: 'Titillium web',sans-serif;"
            >
              <mat-label>{{"Minimum Cart Value" | translate}}</mat-label>
              <input
                type="number"
                matInput
                placeholder="Minimum Cart Value"
                formControlName="min_cart_value"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <!-- ================ ROW-3 ================= -->
        <div class="row">
          <div class="col-md-4">
            <mat-form-field
              appearance="outline"
              class="example-full-width "
              style="font-family: 'Titillium web',sans-serif;"
            >
              <input
                matInput
                [matDatepicker]="picker"
                autocomplete="off"
                [min]="minDate"
                (dateInput)="addEvent($event)"
                (dateChange)="addEvent($event)"
                placeholder="Valid from"
                formControlName="valid_from"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- ============================================================ -->
          <div class="col-md-4">
            <mat-form-field
              appearance="outline"
              class="example-full-width "
              style="font-family: 'Titillium web',sans-serif;"
            >
              <input
                matInput
                [matDatepicker]="picker1"
                autocomplete="off"
                [min]="minDate1"
                placeholder="Valid to"
                formControlName="valid_to"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- ============================================================== -->
          <div class="col-md-4">
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="font-family: 'Titillium web',sans-serif;"
            >
              <mat-label>{{"Max Number of Times" | translate}}</mat-label>
              <input
                type="number"
                matInput
                placeholder="Max Number of Times"
                formControlName="max_no_times"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
          <!-- ============================================= -->
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="font-family: 'Titillium web',sans-serif;"
            >
              <mat-label>{{"Coupon Code" | translate}}</mat-label>
              <input
                type="text"
                matInput
                placeholder="Coupon Code"
                formControlName="coupon_code"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="font-family: 'Titillium web',sans-serif;"
            >
              <mat-label>{{"Max Usage Per User" | translate}}</mat-label>
              <input
                type="number"
                matInput
                placeholder="Max usage of times per user"
                formControlName="max_no_of_times_per_user"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
        </div>
        


        <!-- ======================================== ADD BUTTON ========================================================= -->
        <div class="row mb-5 mt-2 mr-1">
          <div class="offset-md-6">
            <mat-card-actions>
              <button
                (click)="addCoupon()"
                style=" color: white;"
                class=" order-button" [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor"
                mat-raised-button
              >
                {{"ADD" | translate}}
              </button>
            </mat-card-actions>
          </div>
          <!-- ============= delete button====================== -->

          <!-- ================================================= -->
        </div>
      </form>
    </mat-card>
  </div>
  <!-- ======================================================== -->
  <div *ngIf="visible">
    <div class="container-fluid">
    <div class="pl-3  row justify-content-between">
      <h2 class=" product-border-heading" [style.color]="headingcolor" style="font-weight: 600">
        {{"Package Coupons" | translate}}
      </h2> 
      <button (click)="openXl(content_add)" mat-raised-button class="order-button mr-3" [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor">
        <i class="fas fa-plus"></i> {{"Add Package Coupon" | translate}}
      </button>
    </div>
  </div>

    <!-- ==================================coupon cards============================================== -->
    <div class="row">
      <div class="col-md-6" *ngFor="let card of cards; let i = index">
        <mat-card
          class="mt-5 "
         
        >
          <div class="row " style="color: #fff; border-radius: 15px;">
            <div class="col-md-5 col-6">
              <div class="ml-3">
                <h5>
                  {{"Coupon Description" | translate}}
                  <b class="ml-2 text-uppercase" style="letter-spacing: 0.5px;">
                    {{ card.description }}</b
                  >
                </h5>
              </div>
            </div>
            <div class="col-md-5 col-6">
              <div class="text-left">
                <h5>
                  {{"Percent Discount" | translate}}
                  <b>{{ card.discount_percentage }}</b>
                </h5>
                <h5>
                  {{"Maximum Discount" | translate}}(<i class="fas fa-rupee-sign"></i>) :
                  <b>{{ card.max_disc }}</b>
                </h5>
                <h5>
                  {{"Minimum Cart Value" | translate}} :
                  <b>{{ card.min_cart_value }}</b>
                </h5>
              </div>
            </div>
            <div class="col-md-1 col-6" style="color: #fff; margin-top: 3px;">
              <div style="cursor: pointer;">
                <i class="fas fa-pen" (click)="editCoupon(card.cup_id)" title="Edit Coupon"
                  ></i
                >
              </div>
            </div>
            <div class="col-md-1 col-6" style="color: #fff; margin-top: 3px;">
              <div style="cursor: pointer;">
                <i class="fas fa-trash-alt"
                  (click)="delete_coupon(card.cup_id)"
                  title="Delete Coupon"
                  ></i>
              </div>
            </div>
          </div>
          <div class="row" style="background-color:white;">
            <div class="col-md-6">
              <h5>
                {{"Valid From" | translate}}
                <b>{{ card.valid_from }}</b>
              </h5>
            </div>
            <div class="col-md-6">
              <h5>
                {{"Valid To" | translate}}
                <b>{{ card.valid_to }}</b>
              </h5>
            </div>
          </div>

          <div class="row" style="background-color:white;">
            <h5>
              {{"Maximum Usage" | translate}}
              <b>{{ card.max_no_times }}</b>
            </h5>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<!-- //add coupen model start-->


<ng-template #content_add let-modal>
  <div class="modal-header">
    <h5 [style.color]="headingcolor" class="modal-title">{{"Add Package Coupon" | translate}}</h5>

    <i class="fas fa-times cursor-pointer text-danger" (click)="modal.dismiss('Cross click')"
      ></i
    >
  </div>
  <div class="modal-body">
  	<form [formGroup]="couponForm">
        <div class="row mt-2">
          <div class="col-md-12 ">
          
            <mat-form-field
              appearance="fill"
              class="example-full-width"
            
            >
              <mat-label>{{"Coupon Description" | translate}}</mat-label>
              <input
                type="text"
                matInput
                formControlName="description"
                
                required
              />
            </mat-form-field>
          </div>
        </div>

        <!-- ================ ROW-2 ================= -->
        <div class="row">
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
              style="font-family: 'Titillium web',sans-serif;"
            >
              <mat-label>{{"Discount in" | translate}}  (%)</mat-label>
              <input
                type="number"
                matInput
                formControlName="discount_percent"
                class="unstyled"
                min="0"
                required
              />
              <mat-error>invalid number</mat-error>
            </mat-form-field>
          </div>
          <!-- ================================================ -->
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
            >
              <mat-label>{{"Maximum Discount" | translate}}(<i class="fas fa-rupee-sign"></i>)</mat-label>
              <input
                type="number"
                matInput
                placeholder="Maximum Discount"
                formControlName="max_disc"
                min="0"
                required
              />
              <mat-error>invalid number</mat-error>
            </mat-form-field>
          </div>
          <!-- ======================================= -->

          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
           
            >
              <mat-label>{{"Minimum Cart Value" | translate}}</mat-label>
              <input
                type="number"
                matInput
                formControlName="min_cart_value"
                required
              />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
            >
              <mat-label>{{"Max Number of Times" | translate}}</mat-label>
              <input
                type="number"
                matInput
                formControlName="max_no_times"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <!-- ================ ROW-3 ================= -->
        <div class="row">
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width "
            >
            <mat-label>{{"Valid From" | translate}}</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                autocomplete="off"
                [min]="minDate"
                (dateInput)="addEvent($event)"
                (dateChange)="addEvent($event)"
                formControlName="valid_from"
                required
              />
              <mat-datepicker-toggle style="z-index:1111"
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker panelClass="datepickerOne"></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- ============================================================ -->
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width "
            >
            <mat-label>{{"Valid To" | translate}}</mat-label>
              <input
                matInput
                [matDatepicker]="picker1"
                autocomplete="off"
                [min]="minDate1"
                formControlName="valid_to"
                required
              />
              <mat-datepicker-toggle 
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle >
              <mat-datepicker #picker1 panelClass="datepickerOne"></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- ============================================================== -->
       
          <!-- ============================================= -->
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
            >
              <mat-label>{{"Coupon Code" | translate}}</mat-label>
              <input
                type="text"
                matInput
                formControlName="coupon_code"
                required
              />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
            >
              <mat-label>{{"MaxUsage Per User" | translate}}</mat-label>
              <input
                type="number"
                matInput
                placeholder="Max Usage Per User"
                formControlName="max_no_of_times_per_user"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
        </div>
        


        <!-- ======================================== ADD BUTTON ========================================================= -->
        <div class="row mb-2 mt-2 mr-1 justify-content-center">
          <div class="text-center">
            <mat-card-actions>
              <button
                (click)="addCoupon();"
               class=" order-button"  [style.background-color]="buttonbgcolor" [style.color]="buttontextcolor"
                mat-raised-button
              >
                {{"Submit" | translate}} 
              </button>
            </mat-card-actions>
          </div>
          
        </div>
      </form>
   
    <!-- <form [formGroup]="subcat4formU">
    
      <div class="row justify-content-center">
        <button
          class="bg-warning text-white"
          mat-raised-button
          (click)=" modal.close('Save click')"
        >
          Save
        </button>
      </div>
      <hr />
    </form> -->
  </div>
</ng-template>
<!-- add coupen model end -->

<!-- coupon list start -->
<div *ngIf="!showLoader">
  <mat-tab-group mat-align-tabs="around">
    <!-- start current -->
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="product-label">
          {{"Current" | translate}}
        </span>
      </ng-template>
      <div
        class="row justify-content-center mt-5"
        *ngIf="(count ==null || count =='' || countU ==0 || countE =='0') && showLoader==false && !showLoader && flag1==0"
      >
        <div class="col-md-6 text-center">
          <img src="https://img.icons8.com/nolan/64/product.png" />
          <p>{{"Current Coupon is Not Available" | translate}}</p>
          <div
            *ngIf="
              usertype_id == '1' || usertype_id == '3' || usertype_id == '4'
            "
            class="  pt-2"
          >
           <!--  <button
              mat-raised-button
              
              matTooltip="add new product"
              class="text-right bg-warning text-white mr-3"
            >
              Add New Coupon
            </button> -->
          </div>
        </div>
      </div>

      <div
        class="row  d-flex justify-content-around mt-4 head-row"
        *ngIf="count !=null"
      >
        <div class="col-md-5 ">
          <h5 class="text-success pl-2 pt-2">
            {{"Current" | translate}} :
            <span class="box"
              ><b>{{ count }}</b></span
            >
          </h5>
        </div>
        <div class="col-md-5 ">
          <div class="d-flex search-area">
            <input class="product-search"
              (keyup)="applyFilter($event.target.value)"
              placeholder="Search.."
            />
            <button
              mat-raised-button
              (click)="applyFilter($event.target.value)"
              class="bg-info text-white"
            >
              <i class="fas fa-search"></i> {{"SEARCH" | translate}}
            </button>
          </div>
        </div>
        <div *ngIf="usertype_id == '1'" class="col-md-2 text-right add-product">
         <!--  <button
            mat-raised-button
            
            matTooltip="add new coupon"
            class="text-right bg-warning text-white mr-3"
          >
            Add New Coupon
          </button> -->
        </div>
      </div>

      <div class="table table-borderd" *ngIf="count != 0 && flag1==1">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="sno">
            <mat-header-cell *matHeaderCellDef> {{"No." | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">No :</span>
              {{ element.sno }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="coupon_code">
            <mat-header-cell *matHeaderCellDef> {{"Coupon Code" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Coupon Code" | translate}} :</span>
              <span *ngIf="element.coupon_code ==null">- - - - --</span>
            <span *ngIf="element.coupon_code !=null">  {{ element.coupon_code }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> {{"Description" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Description" | translate}} :</span>
              <span *ngIf="element.description ==null">- - - - --</span>
            <span *ngIf="element.description !=null">  {{ element.description }}</span>
            </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="discount_percentage">
            <mat-header-cell *matHeaderCellDef>  {{"Discount" | translate}} (%) </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label"> {{"Discount" | translate}} (%):</span>
              <span *ngIf="element.discount_percentage ==null">- - - - --</span>
            <span *ngIf="element.discount_percentage !=null">  {{ element.discount_percentage }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="valid_from">
            <mat-header-cell *matHeaderCellDef> {{"Valid From" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Valid From" | translate}} :</span>
              <span *ngIf="element.valid_from ==null">- - - - --</span>
            <span *ngIf="element.valid_from !=null">  {{ element.valid_from }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="valid_to">
            <mat-header-cell *matHeaderCellDef> {{"Valid To" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Valid To" | translate}} :</span>
              <span *ngIf="element.valid_to ==null">- - - - --</span>
            <span *ngIf="element.valid_to !=null">  {{ element.valid_to }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="max_disc">
            <mat-header-cell *matHeaderCellDef> {{"Max Discount" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Max Discount" | translate}} :</span>
              <span *ngIf="element.max_disc ==null">- - - - --</span>
            <span *ngIf="element.max_disc !=null">  {{ element.max_disc }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="min_cart_value">
            <mat-header-cell *matHeaderCellDef> {{"Min Cart Value" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Min Cart Value" | translate}} :</span>
              <span *ngIf="element.min_cart_value ==null">- - - - --</span>
            <span *ngIf="element.min_cart_value !=null">  {{ element.min_cart_value }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="max_no_times">
            <mat-header-cell *matHeaderCellDef> {{"Max Usage" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Max Usage" | translate}} :</span>
              <span *ngIf="element.max_no_times ==null">- - - - --</span>
            <span *ngIf="element.max_no_times !=null">  {{ element.max_no_times }}</span>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="max_no_of_times_per_user">
            <mat-header-cell *matHeaderCellDef> {{"Max Usage Per User" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Max Usage Per User" | translate}}:</span>
              <span *ngIf="element.max_no_of_times_per_user ==null">- - - - --</span>
            <span *ngIf="element.max_no_of_times_per_user !=null">  {{ element.max_no_of_times_per_user }}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>{{"Action" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">Actions :</span>

              <div class="pmd-dropdown">
                <button
                  type="button"
                  class=" btn btn-outline-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-ellipsis-v "></i>
                </button>
                <div class="dropdown-menu">
                 
                  <a
                    class="dropdown-item cursor-pointer"
                    *ngIf="
                      usertype_id == 1 || usertype_id == 3 || usertype_id == 4
                    "
                    (click)="fetch_specific_coupon(element.package_cup_no,1);openXl(content_update)"
                    matTooltip="Update Coupon"
                    ><i class="far fa-edit text-danger"></i> {{"Update" | translate}}</a
                  >
                  <a
                    class="dropdown-item cursor-pointer"
                    *ngIf="usertype_id == 1"
                    (click)="deleteCoupon(element.package_cup_no,1)"
                    matTooltip="Delete Coupon"
                    ><i class="far fa-trash-alt text-danger"></i> {{"Delete" | translate}}</a
                  >
                 
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <div
          class="d-flex justify-content-center py-5"
          *ngIf="count != null"
        >
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" *ngIf="offset != 1">
                <a
                  class="page-link "
                  (click)="changeOffset(offset - 1)"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">«</span>
                  <span class="sr-only">{{"Previous" | translate}}</span>
                </a>
              </li>
              <li class="page-item">
                <a class="page-link bg-primary-color">{{ offset }}</a>
              </li>
              <li class="page-item" *ngIf="offset != pages">
                <a
                  class="page-link"
                  (click)="changeOffset(offset + 1)"
                  aria-label="Next"
                >
                  <span aria-hidden="true">»</span>
                  <span class="sr-only">{{"Next" | translate}}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </mat-tab>

    <!-- end current -->
    <!-- start upcoming -->
 <mat-tab>
      <ng-template mat-tab-label>
        <span class="product-label2">
          {{"Upcoming" | translate}}
        </span>
      </ng-template>
      <div
        class="row justify-content-center mt-5"
        *ngIf="(count ==null || countU =='' || countE ==0 ) && showLoader==false && !showLoader && flagU==0"
      >
        <div class="col-md-6 text-center">
          <img src="https://img.icons8.com/nolan/64/product.png" />
          <p>{{"Upcoming Coupon is Not Available" | translate}}</p>
          <div
            *ngIf="
              usertype_id == '1' || usertype_id == '3' || usertype_id == '4'
            "
            class="  pt-2"
          >
            <!-- <button
              mat-raised-button
              
              matTooltip="add new product"
              class="text-right bg-warning text-white mr-3"
            >
              Add New Coupon
            </button> -->
          </div>
        </div>
      </div>

      <div
        class="row  d-flex justify-content-around mt-4 head-row"
        *ngIf="countU !=null"
      >
        <div class="col-md-5 ">
          <h5 class="text-success pl-2 pt-2">
            {{"Upcoming" | translate}} :
            <span class="box"
              ><b>{{ countU }}</b></span
            >
          </h5>
        </div>
        <div class="col-md-5 ">
          <div class="d-flex search-area">
            <input class="product-search"
              (keyup)="applyFilterU($event.target.value)"
              placeholder="Search.."
            />
            <button
              mat-raised-button
              (click)="applyFilterU($event.target.value)"
              class="bg-info text-white"
            >
              <i class="fas fa-search"></i> {{"SEARCH" | translate}}
            </button>
          </div>
        </div>
        <div *ngIf="usertype_id == '1'" class="col-md-2 text-right add-product">
         <!--  <button
            mat-raised-button
            
            matTooltip="add new coupon"
            class="text-right bg-warning text-white mr-3"
          >
            Add New Coupon
          </button> -->
        </div>
      </div>

      <div class="table table-borderd" *ngIf="countU != 0 && flagU==1">
        <mat-table [dataSource]="dataSourceU">
          <ng-container matColumnDef="sno">
            <mat-header-cell *matHeaderCellDef> {{"No." | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"No" | translate}} :</span>
              {{ element.sno }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="coupon_code">
            <mat-header-cell *matHeaderCellDef> {{"Coupon Code" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Coupon Code" | translate}} :</span>
              <span *ngIf="element.coupon_code ==null">- - - - --</span>
            <span *ngIf="element.coupon_code !=null">  {{ element.coupon_code }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> {{"Description" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Description" | translate}} :</span>
              <span *ngIf="element.description ==null">- - - - --</span>
            <span *ngIf="element.description !=null">  {{ element.description }}</span>
            </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="discount_percentage">
            <mat-header-cell *matHeaderCellDef> {{"Percentage Discount" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Percentage Discount" | translate}} :</span>
              <span *ngIf="element.discount_percentage ==null">- - - - --</span>
            <span *ngIf="element.discount_percentage !=null">  {{ element.discount_percentage }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="valid_from">
            <mat-header-cell *matHeaderCellDef> {{"Valid From" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Valid From" | translate}} :</span>
              <span *ngIf="element.valid_from ==null">- - - - --</span>
            <span *ngIf="element.valid_from !=null">  {{ element.valid_from }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="valid_to">
            <mat-header-cell *matHeaderCellDef> {{"Valid To" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Valid To" | translate}} :</span>
              <span *ngIf="element.valid_to ==null">- - - - --</span>
            <span *ngIf="element.valid_to !=null">  {{ element.valid_to }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="max_disc">
            <mat-header-cell *matHeaderCellDef> {{"Max Discount" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Max Discount" | translate}} :</span>
              <span *ngIf="element.max_disc ==null">- - - - --</span>
            <span *ngIf="element.max_disc !=null">  {{ element.max_disc }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="min_cart_value">
            <mat-header-cell *matHeaderCellDef> {{"Min Cart Value" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Min Cart Value" | translate}} :</span>
              <span *ngIf="element.min_cart_value ==null">- - - - --</span>
            <span *ngIf="element.min_cart_value !=null">  {{ element.min_cart_value }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="max_no_times">
            <mat-header-cell *matHeaderCellDef> {{"Max Usage" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Max Usage" | translate}} :</span>
              <span *ngIf="element.max_no_times ==null">- - - - --</span>
            <span *ngIf="element.max_no_times !=null">  {{ element.max_no_times }}</span>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="max_no_of_times_per_user">
            <mat-header-cell *matHeaderCellDef> {{"Max Usage Per User" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Max Usage Per User" | translate}}:</span>
              <span *ngIf="element.max_no_of_times_per_user ==null">- - - - --</span>
            <span *ngIf="element.max_no_of_times_per_user !=null">  {{ element.max_no_of_times_per_user }}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>{{"Action" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Actions" | translate}} :</span>

              <div class="pmd-dropdown">
                <button
                  type="button"
                  class=" btn btn-outline-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-ellipsis-v "></i>
                </button>
                <div class="dropdown-menu">
                 
                  <a
                    class="dropdown-item cursor-pointer"
                    *ngIf="
                      usertype_id == 1 || usertype_id == 3 || usertype_id == 4
                    "
                    (click)="fetch_specific_coupon(element.package_cup_no,2);openXl(content_update)"
                    matTooltip="Update Coupon"
                    ><i class="far fa-edit text-danger"></i> {{"Update" | translate}}</a
                  >
                  <a
                    class="dropdown-item cursor-pointer"
                    *ngIf="usertype_id == 1"
                    (click)="deleteCoupon(element.package_cup_no,2)"
                    matTooltip="Delete Coupon"
                    ><i class="far fa-trash-alt text-danger"></i> {{"Delete" | translate}}</a
                  >
                 
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumnsU"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsU"></mat-row>
        </mat-table>

        <div
          class="d-flex justify-content-center py-5"
          *ngIf="countU != null"
        >
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" *ngIf="offsetU != 1">
                <a
                  class="page-link "
                  (click)="changeOffsetU(offsetU - 1)"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">«</span>
                  <span class="sr-only">{{"Previous" | translate}}</span>
                </a>
              </li>
              <li class="page-item">
                <a class="page-link bg-primary-color">{{ offsetU }}</a>
              </li>
              <li class="page-item" *ngIf="offsetU != pagesU">
                <a
                  class="page-link"
                  (click)="changeOffsetU(offsetU + 1)"
                  aria-label="Next"
                >
                  <span aria-hidden="true">»</span>
                  <span class="sr-only">{{"Next" | translate}}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </mat-tab>
    <!-- end upcoming -->
    <!-- start expire -->
 <mat-tab>
      <ng-template mat-tab-label>
        <span class="product-label3">
          {{"Expired" | translate}}
        </span>
      </ng-template>
      <div
        class="row justify-content-center mt-5"
        *ngIf="(count ==null || count =='' || countU ==0 || countE =='0') && showLoader==false && !showLoader && flagE==0"
      >
        <div class="col-md-6 text-center">
          <img src="https://img.icons8.com/nolan/64/product.png" />
          <p>{{"Expired Coupon is Not Available" | translate}}</p>
          <div
            *ngIf="
              usertype_id == '1' || usertype_id == '3' || usertype_id == '4'
            "
            class="  pt-2"
          >
           <!--  <button
              mat-raised-button
              
              matTooltip="add new product"
              class="text-right bg-warning text-white mr-3"
            >
              Add New Coupon
            </button> -->
          </div>
        </div>
      </div>

      <div
        class="row  d-flex justify-content-around mt-4 head-row"
        *ngIf="countE !=null && flagE==1"
      >
        <div class="col-md-5 ">
          <h5 class="text-success pl-2 pt-2">
            {{"Expired" | translate}} :
            <span class="box"
              ><b>{{ countE }}</b></span
            >
          </h5>
        </div>
        <div class="col-md-5 ">
          <div class="d-flex search-area">
            <input class="product-search"
              (keyup)="applyFilterE($event.target.value)"
              placeholder="Search.."
            />
            <button
              mat-raised-button
              (click)="applyFilterE($event.target.value)"
              class="bg-info text-white"
            >
              <i class="fas fa-search"></i> {{"SEARCH" | translate}}
            </button>
          </div>
        </div>
        <div *ngIf="usertype_id == '1'" class="col-md-2 text-right add-product">
          <!-- <button
            mat-raised-button
            
            matTooltip="add new coupon"
            class="text-right bg-warning text-white mr-3"
          >
            Add New Coupon
          </button> -->
        </div>
      </div>

      <div class="table table-borderd" *ngIf="countE != 0 && flagE==1">
        <mat-table [dataSource]="dataSourceE">
          <ng-container matColumnDef="sno">
            <mat-header-cell *matHeaderCellDef> {{"No." | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"No" | translate}} :</span>
              {{ element.sno }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="coupon_code">
            <mat-header-cell *matHeaderCellDef> {{"Coupon Code" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Coupon Code" | translate}} :</span>
              <span *ngIf="element.coupon_code ==null">- - - - --</span>
            <span *ngIf="element.coupon_code !=null">  {{ element.coupon_code }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> {{"Description" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Description" | translate}} :</span>
              <span *ngIf="element.description ==null">- - - - --</span>
            <span *ngIf="element.description !=null">  {{ element.description }}</span>
            </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="discount_percentage">
            <mat-header-cell *matHeaderCellDef> {{"Percentage Discount" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Percentage Discount" | translate}} :</span>
              <span *ngIf="element.discount_percentage ==null">- - - - --</span>
            <span *ngIf="element.discount_percentage !=null">  {{ element.discount_percentage }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="valid_from">
            <mat-header-cell *matHeaderCellDef> {{"Valid From" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Valid From" | translate}} :</span>
              <span *ngIf="element.valid_from ==null">- - - - --</span>
            <span *ngIf="element.valid_from !=null">  {{ element.valid_from }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="valid_to">
            <mat-header-cell *matHeaderCellDef> {{"Valid To" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Valid To" | translate}} :</span>
              <span *ngIf="element.valid_to ==null">- - - - --</span>
            <span *ngIf="element.valid_to !=null">  {{ element.valid_to }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="max_disc">
            <mat-header-cell *matHeaderCellDef> {{"Max Discount" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Max Discount" | translate}} :</span>
              <span *ngIf="element.max_disc ==null">- - - - --</span>
            <span *ngIf="element.max_disc !=null">  {{ element.max_disc }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="min_cart_value">
            <mat-header-cell *matHeaderCellDef> {{"Min Cart Value" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Min Cart Value" | translate}} :</span>
              <span *ngIf="element.min_cart_value ==null">- - - - --</span>
            <span *ngIf="element.min_cart_value !=null">  {{ element.min_cart_value }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="max_no_times">
            <mat-header-cell *matHeaderCellDef> {{"Max Usage" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Max Usage" | translate}} :</span>
              <span *ngIf="element.max_no_times ==null">- - - - --</span>
            <span *ngIf="element.max_no_times !=null">  {{ element.max_no_times }}</span>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="max_no_of_times_per_user">
            <mat-header-cell *matHeaderCellDef> {{"Max Usage Per User" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Max Usage Per User" | translate}}:</span>
              <span *ngIf="element.max_no_of_times_per_user ==null">- - - - --</span>
            <span *ngIf="element.max_no_of_times_per_user !=null">  {{ element.max_no_of_times_per_user }}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>{{"Action" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="mobile-label">{{"Actions" | translate}} :</span>

              <div class="pmd-dropdown">
                <button
                  type="button"
                  class=" btn btn-outline-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-ellipsis-v "></i>
                </button>
                <div class="dropdown-menu">
                 
                  <a
                    class="dropdown-item cursor-pointer"
                    *ngIf="
                      usertype_id == 1 || usertype_id == 3 || usertype_id == 4
                    "
                    (click)="fetch_specific_coupon(element.package_cup_no);openXl(content_update,3)"
                    matTooltip="Update Coupon"
                    ><i class="far fa-edit text-danger"></i> {{"Update" | translate}}</a
                  >
                  <a
                    class="dropdown-item cursor-pointer"
                    *ngIf="usertype_id == 1"
                    (click)="deleteCoupon(element.package_cup_no,3)"
                    matTooltip="Delete Coupon"
                    ><i class="far fa-trash-alt text-danger"></i> {{"Delete" | translate}}</a
                  >
                 
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumnsE"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsE"></mat-row>
        </mat-table>

        <div
          class="d-flex justify-content-center py-5"
          *ngIf="allDeviceDataE != null"
        >
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" *ngIf="offsetE != 1">
                <a
                  class="page-link "
                  (click)="changeOffsetE(offsetE - 1)"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">«</span>
                  <span class="sr-only">{{"Previous" | translate}}</span>
                </a>
              </li>
              <li class="page-item">
                <a class="page-link bg-primary-color">{{ offsetE }}</a>
              </li>
              <li class="page-item" *ngIf="offsetE != pagesE">
                <a
                  class="page-link"
                  (click)="changeOffsetE(offsetE + 1)"
                  aria-label="Next"
                >
                  <span aria-hidden="true">»</span>
                  <span class="sr-only">{{"Next" | translate}}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </mat-tab>
    <!-- end expire -->
</mat-tab-group>
</div>

<!-- coupon list end -->
<!-- //update coupen model start-->


<ng-template #content_update let-modal>
  <div class="modal-header">
    <h4 [style.color]="headingcolor" class="modal-title">{{"Update Package Coupon" | translate}}</h4>

    <i class="fas fa-times cursor-pointer" (click)="modal.dismiss('Cross click')"
      ></i
    >
  </div>
  <div class="modal-body">
    <form [formGroup]="couponFormU">
        <div class="row">
          <div class="col-md-12 ">
            <div
              class="row pb-3"
              style="display: flex;
          justify-content: center;
          position: relative;
          
          line-height: 1.9;
          top: -15px;"
            >
             
            </div>
            <mat-form-field
              appearance="fill"
              class="example-full-width"
           
            >
              <mat-label>{{"Coupon Description" | translate}}</mat-label>
              <input
                type="text"
                matInput
                
                formControlName="description"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
        </div>

        <!-- ================ ROW-2 ================= -->
        <div class="row">
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
            >
              <mat-label>{{"Discount in Percent" | translate}} %</mat-label>
              <input
                type="number"
                matInput
                placeholder="Discount in Percent %"
                formControlName="discount_percent"
                class="unstyled"
                min="0"
                required
              />
              <mat-error>invalid number</mat-error>
            </mat-form-field>
          </div>
          <!-- ================================================ -->
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
             
            >
              <mat-label>{{"Maximum Discount" | translate}}(<i class="fas fa-rupee-sign"></i>)</mat-label>
              <input
                type="number"
                matInput
                placeholder="Maximum Discount"
                formControlName="max_disc"
                class="unstyled"
                min="0"
                required
              />
              <mat-error>invalid number</mat-error>
            </mat-form-field>
          </div>
          <!-- ======================================= -->

        </div>
        <!-- ================ ROW-3 ================= -->
        <div class="row">
          
        
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width "
           
            >
              <input
                matInput
                [matDatepicker]="picker"
                autocomplete="off"
                
                (dateInput)="addEvent($event)"
                (dateChange)="addEvent($event)"
                placeholder="Valid from"
                formControlName="valid_from"
                required
              />
              <mat-datepicker-toggle style="z-index:1111"
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker panelClass="datepickerOne"></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- ============================================================ -->
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width "
            >
              <input
                matInput
                [matDatepicker]="picker1"
                autocomplete="off"
                [min]="minDate1"
                placeholder="Valid to"
                formControlName="valid_to"
                required
              />
              <mat-datepicker-toggle 
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle >
              <mat-datepicker #picker1 panelClass="datepickerOne"></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- ============================================================== -->
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
             
            >
              <mat-label>{{"Minimum Cart Value" | translate}}</mat-label>
              <input
                type="number"
                matInput
                placeholder="Minimum Cart Value"
                formControlName="min_cart_value"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
            >
              <mat-label>{{"Max Number of Times" | translate}}</mat-label>
              <input
                type="number"
                matInput
                placeholder="Max Number of Times"
                formControlName="max_no_times"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
          <!-- ============================================= -->
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
            >
              <mat-label>{{"Coupon Code" | translate}}</mat-label>
              <input
                type="text"
                matInput
                placeholder="Coupon Code"
                formControlName="coupon_code"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field
              appearance="fill"
              class="example-full-width"
             
            >
              <mat-label>{{"Max Usage Per User" | translate}}</mat-label>
              <input
                type="number"
                matInput
                placeholder="Max Usage Per User"
                formControlName="max_no_of_times_per_user"
                class="unstyled"
                required
              />
            </mat-form-field>
          </div>
        </div>
        


        <!-- ======================================== ADD BUTTON ========================================================= -->
        <div class="row mb-2 mt-2 mr-1">
          <div class="offset-md-6">
            <mat-card-actions>
              <button
                (click)="updateCoupon();"
               class="order-button " [style.color]="buttontextcolor" [style.background-color]="buttonbgcolor"
                mat-raised-button
              >
                {{"Update" | translate}}
              </button>
            </mat-card-actions>
          </div>
          
        </div>
      </form>
   
  </div>
</ng-template>
<!-- update coupen model end -->
</div>