import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { NinetoysserviceService } from './ninetoysservice.service';
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  message = this.currentMessage.asObservable();
  constructor(private angularFireMessaging: AngularFireMessaging , private adminService: NinetoysserviceService) {
    this.angularFireMessaging.messaging.subscribe(_messaging => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }
  rtoken ;
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      token => {
        this.rtoken = token;
        sessionStorage.setItem('noti_token',this.rtoken)
        var topic = "connect_comp"+sessionStorage.getItem('comp_num');
       this.adminService.subscribe_topic({topic_name : topic , token : this.rtoken,user_num:sessionStorage.getItem('user_num'),
          access_token:sessionStorage.getItem('access_token')}).subscribe(data =>{
          //data)
          if(data['status']=='1'){
            //"topic subscribed")

          }else{
            //"topic not subscribed")
          }
        })
      },
      err => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(payload => {
      this.currentMessage.next(payload);
      console.log(payload);
    });
  }
}
