

import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray
} from "@angular/forms";
import { MatDialog, MatStepper } from "@angular/material";
import { NinetoysserviceService } from "../ninetoysservice.service";
import { MatSnackBar } from "@angular/material";
import { AlertService, AuthenticationService } from "../_services";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";


@Component({
  selector: 'app-add-product-variant',
  templateUrl: './add-product-variant.component.html',
  styleUrls: ['./add-product-variant.component.css']
})
export class AddProductVariantComponent implements OnInit {
  headingcolor = this.cookie.get('headingcolor');
  buttonbgcolor = this.cookie.get('buttonbgcolor');
 buttontextcolor = this.cookie.get('buttontextcolor');
    is_checked=false;
    variant_color='color';
    variant_size='size';

	notbuy=true;
  ship_ask=true;
  noexe;
  productsArray;
  returns;
  skuset;
  colors;
  colorArray=[];
	mrpset;
	qtyset;
	taxset;
  prod_groupset;
	sellingset;
	onedayrentset;
	fivedayrentset;
	shippingset;
  imgHeight;
  imgWidth;
  tableCheck=true;
  saveCheck=false;
 rentShow  = false;
 public usertype_id = sessionStorage.getItem("usertype_id");
 public showvartable =false;
 product_no_var_flag = sessionStorage.getItem("product_no_var_flag");
  // sessionStorage.setItem("", '1');
  imgHeight2;
  imgWidth2;
  loading=true;
  isLinear = false;
  ratingcount=true;
  ratingcountMember=true;
  brand_lists;
   limitCountRateMember = 0;
  limitCountRate = 0;
  limitCountSpec = 0;
  limitCountCat = 0;
  limitCountTag = 0;
  rates2;
  checkRate=true;
  tags;
  public firstForm = false;
  public secondForm = false;
  public thirdForm = false;
  public fourthForm = false;
  public fifthForm = false;
  public sixthForm = false;
  imagPush=[];
  imagPush22=[];
  secondF=0;
  thirdF=0;
  fourthF=0;
  fifthF=0;
  cust_reg_enable=false;

  active1=false;
  active2=false;
  active3=false;
  active4=true;
  active5=false;
  active6=false;
existImage=false;
product_imag;
product_imag_prev=[];
product_imag_id=[];

  addProductForm: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  imageArray2 = [];
  imageArray22 =new Array();
  imageArray22Preview =new Array();
  imageArray2A = [];
  imageArray2Preview = [];
  images: File;
  thumbnail1: File;
  imageArrayChoose=[];
  imageArrayChoosePreview=[];

  imageArray: File = null;
  imageArraynew: File = null;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;

  limitCount = 1;

  updateFormT: FormGroup;
  buisnessForm: FormGroup;
  regAddForm: FormGroup;
  bankForm: FormGroup;
  updateForm: FormGroup;
  updateFormS: FormGroup;
  updateFormC: FormGroup;
  updateFormR: FormGroup;
  updateFormRMember: FormGroup;
  leagueForm: FormGroup;
  imageForm: FormGroup;
  tableForm: FormGroup;
  categories;
  cat=[];
  return_policy=false;
  cat2=[];
  gallery_id=[];
  subcategories1=null;
  subcategories2=null;
  subcategories3=null;
  subcategories4=null;

   product=null;
   product_category=null;
   product_rate=null;
   product_tags=null;
   product_specification=null;
   product_image=null;

   productC=false;
   product_categoryC=false;
   product_rateC=false;
   product_tagsC=false;
   product_specificationC=false;
   product_imageC=false;
   taxes;
   checkId;

  public access_token = sessionStorage.getItem("access_token");
  public user_num = sessionStorage.getItem("user_num");
  public comp_num = sessionStorage.getItem("comp_num");
   public product_no = sessionStorage.getItem("product_no_var");
    // public product_no = "255";
   panelOpenState = true;
   addVariants : FormGroup;
   variantscount =0 ;
   addVariants2 : FormGroup;
   variantscount2 =0 ;
   dynamicArray =[];  
  newDynamic: any = {}; 

  constructor(
    private _formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private cookie: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private adminservice: NinetoysserviceService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
  ) {}
  @ViewChild("stepper", { static: true }) stepper: MatStepper;

  ngOnInit() {
    
    this.leagueForm = this._formBuilder.group({
     
      variants: this._formBuilder.array([this.teams])
    });
    this.imageForm = this._formBuilder.group({
     
      variants: this._formBuilder.array([])
    });

     this.tableForm = this._formBuilder.group({
     
      variants: this._formBuilder.array([])
    });
    this.compSettingsSize(this.comp_num);
     this.compSettingsColor(this.comp_num);
         this.compSettingsCustReg(this.comp_num);

     this.compSettings(this.comp_num);
      this.compSettings2(this.comp_num);

    this.secondFormGroup = this._formBuilder.group({});
      this.updateForm = this._formBuilder.group({
      items: this._formBuilder.array([])
    });
   
    this.updateFormR = this._formBuilder.group({
      rates: this._formBuilder.array([])
    });
     this.updateFormRMember = this._formBuilder.group({
      
      rates: this._formBuilder.array([])
    });
   
    var control2 = this.updateForm.get("items") as FormArray;
    control2.push(this.createItem());
      this.fetch_product_rates_all();
      this.fetch_product();
       this.fetch_product_tax_all();
   

  }
  addPlayer(team) {
    team.get("sizes").push(this.players);
  }
  addTeam() {
    (this.leagueForm.get("variants") as FormArray).push(this.teams);
  }
  removeItemSize(i,playerIndex): void {
     var control = this.leagueForm.get("variants") as FormArray;
      var control2 = control.at(i).get("sizes") as FormArray;
     
     control2.removeAt(playerIndex);
    this.limitCountSpec--;
  }

  removeimageItemSize(i,playerIndex): void {
     var control = this.imageForm.get("variants") as FormArray;
      var control2 = control.at(i).get("sizes") as FormArray;
     
     control2.removeAt(playerIndex);
     // var flag=false;
     if(this.imageArray22[i][playerIndex]==this.imageArray22[i].length){
          this.imageArray22[i].splice(playerIndex, 1);
          this.imageArray22Preview[i].splice(playerIndex, 1);

     }
     else{
      for (let count = playerIndex; count < this.imageArray22[i].length; count++) {
          if(this.imageArray22[i][count+1]) {
             this.imageArray22[i][count]=this.imageArray22[i][count+1];
             this.imageArray22Preview[i][count]=this.imageArray22Preview[i][count+1];
     
          }
          if(this.imageArray22Preview[i][count]==this.imageArray22[i].length){
             this.imageArray22[i].splice(count, 1);
             this.imageArray22Preview[i].splice(count, 1);

          }
      }
     }
  
     
    this.limitCountSpec--;
  }
  removeItemColor(i): void {
    const control = this.leagueForm.get("variants") as FormArray;
    control.removeAt(i);
    this.limitCountSpec--;
  }
  get teams(): FormGroup {
    return this._formBuilder.group({
      color: [""],
      sizes: this._formBuilder.array([this.players])
    });
  }
   get imageteams(): FormGroup {
    return this._formBuilder.group({
      color: [""],
      sizes: this._formBuilder.array([this.imageplayers])
    });
  }
  addimagePlayer(team) {
    // var con=this.imageForm.get("variants") as FormArray;
    team.get("sizes").push(this.imageplayers);
  }
  
  addimageTeam() {
    (this.imageForm.get("variants") as FormArray).push(this.imageteams);
  }
  get players(): FormGroup {
    return this._formBuilder.group({
     
      size: [""]
    });
  }
   get imageplayers(): FormGroup {
    return this._formBuilder.group({
     
      size: [""]
    });
  }
 
    get tables(): FormGroup {
    return this._formBuilder.group({
      sku:[""],
      prod_group:[""],
      color: [""],
      size:[""],
      mrp:[""],
      qty:[""],
      tax_num:["1"],
      prices: this._formBuilder.array([this.pricing]),
       member_prices: this._formBuilder.array([this.pricing])
    });
  }
   get tables3(): FormGroup {
    return this._formBuilder.group({
     
      prices: this._formBuilder.array([this.pricing]),
       member_prices: this._formBuilder.array([this.pricing])
    });
  }
   get tables2(): FormGroup {
    return this._formBuilder.group({
     
      prices: this._formBuilder.array([]),
       member_prices: this._formBuilder.array([])
    });
  }
  pricing():FormGroup {
    return this._formBuilder.group({
     
      rate: [""],
      id:[""],
      rate_name:[""]
    });
  }


  
  addProductVariant(i){
    this.showvartable=true;
    this.addRow2(i);
  }
  addRow2(i) {    
       
      
       var control = this.leagueForm.get("variants") as FormArray;
      
        var in2=this.tableForm.get("variants") as FormArray;
          
       
        var color=control.at(i)['controls'].color.value;
       
     const control2 = control.at(i).get("sizes") as FormArray;
     
      if(control2.length>0){
          for(let n=0;n<control2.length;n++){
            var size=control2.at(n)['controls'].size.value;
            // var send=sending.controls.at(i);
             (this.tableForm.get("variants") as FormArray).push(
            	this._formBuilder.group({
                 sku:[''],
                 prod_group:this.prod_groupset,
			      color: color,
			      size:size,
			      mrp:this.mrpset,
            return_days:this.returns,
			      tax_num:this.taxset,
          
			      qty:this.qtyset,
            product_no:this.product_no,
            // this.addItemR2(n)
			      prices: this._formBuilder.array([]) ,
             member_prices: this._formBuilder.array([])
			    }));
               var sending=this.tableForm.get("variants") as FormArray;
        var len=(sending.length)-1;
         
             let y= this.addItemR2(sending,len);

          }
           var controlling = this.leagueForm.get("variants") as FormArray;
            controlling.removeAt(i);
      }
      else{

          (this.tableForm.get("variants") as FormArray).push(
            	this._formBuilder.group({
                sku:[''],
                 prod_group:this.prod_groupset,
		      color: color,
		      size:size,
		      mrp:this.mrpset,
          return_days:this.returns,
           tax_num:this.taxset,
		      
		      qty:this.qtyset,
           product_no:this.product_no,
		      prices: this._formBuilder.array([]),
           member_prices: this._formBuilder.array([])
		    }));
           var sending=this.tableForm.get("variants") as FormArray;
           var len=(sending.length-1);
        
           let y= this.addItemR2(sending,len);
                    
             var controlling = this.leagueForm.get("variants") as FormArray;
            controlling.removeAt(i); 

      }
      this.saveCheck=true;
      return true;  
  } 
   addItemR2(x,a) {
     //2);
    var i= (this.tableForm.get("variants") as FormArray);
    
    const control = i.at(a).get("prices") as FormArray;
       if(this.cust_reg_enable==false){//not member price
    //31);

       for (let count = 0; count < this.rates2.length; count++) {
         const emailFormArray2 = control;
        
            var index =-1;
         //start
            if(index<0){
              
                 if(this.rates2[count].is_rent == '1' || this.rates2[count].is_rent == 1){
                    if( this.rentShow){
                        if(this.product_rate.length>0){
                          var index2 = this.product_rate.findIndex(x => x.rate_type == this.rates2[count].id);
                          if(index2>-1){
                            const control2 = i.at(a).get("prices") as FormArray;
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                rate: [this.product_rate[index2].rate],
                                id: this.rates2[count].id,
                                rate_name:this.rates2[count].name
                              })
                            );
                           
                            this.limitCountRate++;
                          }
                          else{
                            const control2 = i.at(a).get("prices") as FormArray;
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                rate: [''],
                                id: this.rates2[count].id,
                                rate_name:this.rates2[count].name
                              })
                            );
                           
                            this.limitCountRate++;
                        
                          }
                        }
                        else{
                           const control2 = i.at(a).get("prices") as FormArray;
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                rate: [''],
                                id: this.rates2[count].id,
                                rate_name:this.rates2[count].name
                              })
                            );
                           
                            this.limitCountRate++;
                        }
                    }
                   
                  }
                  else if(this.rates2[count].is_rent == '0' || this.rates2[count].is_rent == 0){
                    if( this.notbuy){
                      if(this.product_rate.length>0){
                       
                        var index2 = this.product_rate.findIndex(x => x.rate_type == this.rates2[count].id);
                        if(index2>-1){
                     
                           
                              const control2 = i.at(a).get("prices") as FormArray;
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [this.product_rate[index2].rate],
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                            this.limitCountRate++;
                        }
                        else{
                           const control2 = i.at(a).get("prices") as FormArray;
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [""],
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                            this.limitCountRate++;
                      
                        }
                      }
                      else{
                         const control2 = i.at(a).get("prices") as FormArray;
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [""],
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                            this.limitCountRate++;
                      }
                    }
                   
                  }
                  else{
                     if( this.ship_ask){
                      if(this.product_rate.length>0){
                      
                            var index2 = this.product_rate.findIndex(x => x.rate_type == this.rates2[count].id);
                     
                          if(index2>-1){
                              const control2 = i.at(a).get("prices") as FormArray;
                             
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [this.product_rate[index2].rate],
                               
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                          }
                          else{
                             const control2 = i.at(a).get("prices") as FormArray;
                             
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [''],
                               
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                          }
                      }
                      else{
                           const control2 = i.at(a).get("prices") as FormArray;
                             
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [''],
                               
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                      }
                    }  
                  }
                
            }
            //end
              
           
        }

    
   this.ratingcount=false;
    this.limitCountRate++;
  }
  else{//member price
this.addItemR2Member(x,a);
  }
  }


  // start member price
addItemR2Member(x,a){
//3);
    var i= (this.tableForm.get("variants") as FormArray);
    
    const control = i.at(a).get("prices") as FormArray;
    // start 

var memIndex1=this.product_rate.findIndex(x => ( x.is_member=='N'));
var memIndex2=this.product_rate.findIndex(x => ( x.is_member=='Y'));
if(memIndex1!=-1 && memIndex2==-1){
  this.mem_rate(x,a);
}
    // end 
    
       for (let count = 0; count < this.rates2.length; count++) {
         const emailFormArray2 = control;
        
            var index =-1;
         //start
            if(index<0){
              
                 if(this.rates2[count].is_rent == '1' || this.rates2[count].is_rent == 1){
                    if( this.rentShow){
                        if(this.product_rate.length>0){
                          //5);

                          var index2 = this.product_rate.findIndex(x => (x.rate_type == this.rates2[count].id) && x.is_member=='N');
                          if(index2>-1){
                            const control2 = i.at(a).get("prices") as FormArray;
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                rate: [this.product_rate[index2].rate],
                                id: this.rates2[count].id,
                                rate_name:this.rates2[count].name
                              })
                            );
                           
                            this.limitCountRate++;
                          }
                           if(this.product_rate.findIndex(x => (x.rate_type == this.rates2[count].id) && x.is_member=='Y') >-1){//member check
                            //this.product_rate.findIndex(x => (x.rate_type == this.rates2[count].id) && x.is_member=='Y'));

                             var index2 = this.product_rate.findIndex(x => (x.rate_type == this.rates2[count].id) && x.is_member=='Y');
                         
                                    if(index2>-1){
                                      const control2Member = i.at(a).get("member_prices") as FormArray;
                                       control2Member.push(
                                        this._formBuilder.group({
                                          // rate: [""],
                                          rate: [this.product_rate[index2].rate],
                                          id: this.rates2[count].id,
                                          rate_name:this.rates2[count].name
                                        })
                                      );
                                     
                                      this.limitCountRateMember++;
                                    }
                                   
                          } 
                        }
                        else{//not product rate
                          //6);
                           const control2 = i.at(a).get("prices") as FormArray;
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                rate: [''],
                                id: this.rates2[count].id,
                                rate_name:this.rates2[count].name
                              })
                            );
                           
                            this.limitCountRate++;
                              // start member
                             const control2Member = i.at(a).get("member_prices") as FormArray;
                             control2Member.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [""],
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                            this.limitCountRateMember++;
                            // end member
                        }
                    }
                   
                  }
                  else if(this.rates2[count].is_rent == '0' || this.rates2[count].is_rent == 0){
                    if( this.notbuy){
                      if(this.product_rate.length>0){
                       
                        var index2 = this.product_rate.findIndex(x => (x.rate_type == this.rates2[count].id) && x.is_member=='N');
                        if(index2>-1){
                     
                            //61);
                              const control2 = i.at(a).get("prices") as FormArray;
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [this.product_rate[index2].rate],
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                            this.limitCountRate++;
                        }
                         if(this.product_rate.findIndex(x => (x.rate_type == this.rates2[count].id) && x.is_member=='Y')>-1){//member check
                           //36);
                             var index2 = this.product_rate.findIndex(x => (x.rate_type == this.rates2[count].id) && x.is_member=='Y');
                         
                                    if(index2>-1){
                                      const control2Member = i.at(a).get("member_prices") as FormArray;
                                       control2Member.push(
                                        this._formBuilder.group({
                                          // rate: [""],
                                          rate: [this.product_rate[index2].rate],
                                          id: this.rates2[count].id,
                                          rate_name:this.rates2[count].name
                                        })
                                      );
                                     
                                      this.limitCountRateMember++;
                                    }
                                  //   else{
                                     
                                  //        const control2 = i.at(a).get("prices") as FormArray;
                                  //          control.push(
                                  //           this._formBuilder.group({
                                  //             // rate: [""],
                                  //              rate: [""],
                                  //             id: this.rates2[count].id,
                                  //              rate_name:this.rates2[count].name
                                  //           })
                                  //         );
                                  //         this.limitCountRate++;

                                  // // start member
                                  //          const control2Member = i.at(a).get("member_prices") as FormArray;
                                  //          control2Member.push(
                                  //           this._formBuilder.group({
                                  //             // rate: [""],
                                  //             rate: [''],
                                  //             id: this.rates2[count].id,
                                  //             rate_name:this.rates2[count].name
                                  //           })
                                  //         );
                                         
                                  //         this.limitCountRateMember++;
                                  //     // end member
                                  //   }
                      
                        }
                      }
                      else{//not product rate
                         //67);
                         const control2 = i.at(a).get("prices") as FormArray;
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [""],
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                            this.limitCountRate++;

                            // start member
                             const control2Member = i.at(a).get("member_prices") as FormArray;
                             control2Member.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [""],
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                            this.limitCountRateMember++;
                            // end member
                      }
                    }
                   
                  }
                  else{
                     if( this.ship_ask){
                      if(this.product_rate.length>0){
                      
                            var index2 = this.product_rate.findIndex(x => (x.rate_type == this.rates2[count].id) && x.is_member=='N');
                     
                          if(index2>-1){
                             //67);
                              const control2 = i.at(a).get("prices") as FormArray;
                             
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [this.product_rate[index2].rate],
                               
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                          }
                           if(this.product_rate.findIndex(x => (x.rate_type == this.rates2[count].id) && x.is_member=='Y')>-1){
                             //68);

                             var index2 = this.product_rate.findIndex(x => (x.rate_type == this.rates2[count].id) && x.is_member=='Y');
                         
                                    if(index2>-1){
                                      const control2Member = i.at(a).get("member_prices") as FormArray;
                                       control2Member.push(
                                        this._formBuilder.group({
                                          // rate: [""],
                                          rate: [this.product_rate[index2].rate],
                                          id: this.rates2[count].id,
                                          rate_name:this.rates2[count].name
                                        })
                                      );
                                     
                                      this.limitCountRateMember++;
                                    }
                               //      else{
                                     
                               //           const control2 = i.at(a).get("prices") as FormArray;
                                         
                               //           control.push(
                               //            this._formBuilder.group({
                               //              // rate: [""],
                               //               rate: [''],
                                           
                               //              id: this.rates2[count].id,
                               //               rate_name:this.rates2[count].name
                               //            })
                               //          );


                               // // start member
                               //         const control2Member = i.at(a).get("member_prices") as FormArray;
                               //         control2Member.push(
                               //          this._formBuilder.group({
                               //            // rate: [""],
                               //            rate: [''],
                               //            id: this.rates2[count].id,
                               //            rate_name:this.rates2[count].name
                               //          })
                               //        );
                                     
                               //        // end member
                               //      }
                          }
                      }
                      else{//not product rate
                         //69);
                           const control2 = i.at(a).get("prices") as FormArray;
                             
                             control.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [''],
                               
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                               // start member
                             const control2Member = i.at(a).get("member_prices") as FormArray;
                             control2Member.push(
                              this._formBuilder.group({
                                // rate: [""],
                                 rate: [""],
                                id: this.rates2[count].id,
                                 rate_name:this.rates2[count].name
                              })
                            );
                          
                            // end member
                      }
                    }  
                  }
                
            }
            //end
              
           
        }

    
   this.ratingcount=false;
    this.limitCountRate++;

      this.ratingcountMember=false;
    this.limitCountRateMember++;
  
  
}
  // end member price
  // start 

mem_rate(x,a){
    var i= (this.tableForm.get("variants") as FormArray);
  
  for (let count = 0; count < this.rates2.length; count++) {
      if(this.rates2[count].is_rent == '1' || this.rates2[count].is_rent == 1){
                    if( this.rentShow){
                       const control2Member = i.at(a).get("member_prices") as FormArray;
                                       control2Member.push(
                                        this._formBuilder.group({
                                          // rate: [""],
                                          rate: [''],
                                          id: this.rates2[count].id,
                                          rate_name:this.rates2[count].name
                                        })
                                      );
                                     
                                      this.limitCountRateMember++;
                    }
      }
      else if(this.rates2[count].is_rent == '0' || this.rates2[count].is_rent == 0){
                    if( this.notbuy){
                                    const control2Member = i.at(a).get("member_prices") as FormArray;
                                       control2Member.push(
                                        this._formBuilder.group({
                                          // rate: [""],
                                          rate: [''],
                                          id: this.rates2[count].id,
                                          rate_name:this.rates2[count].name
                                        })
                                      );
                                     
                                      this.limitCountRateMember++;
                    }
      }
      else{
                     if( this.ship_ask){
                       const control2Member = i.at(a).get("member_prices") as FormArray;
                                       control2Member.push(
                                        this._formBuilder.group({
                                          // rate: [""],
                                          rate: [''],
                                          id: this.rates2[count].id,
                                          rate_name:this.rates2[count].name
                                        })
                                      );
                                     
                                      this.limitCountRateMember++;
                     }
      }
    
  }
}
  // end
  
   add_product_variation(){
     this.loading=false;
     this.noexe=false;

     let postData=this.tableForm.value;
   for(let h=0;h<postData.variants.length;h++){
      if(postData.variants[h].mrp=='' || postData.variants[h].mrp==null){
        this.noexe=true;
         this.loading=true;
            this.snackbar.open(this.getCloseWord("MRP field is required."), "", {
            duration: 3000
          });
       }
       if(postData.variants[h].qty=='' || postData.variants[h].qty==null){
         this.noexe=true;
          this.loading=true;
            this.snackbar.open(this.getCloseWord("Qty field is required."), "", {
            duration: 3000
          });
       }

     var flag=0;
     for(let k=0;k<postData.variants[h].prices.length;k++){
       if(postData.variants[h].prices[k].rate=='' || postData.variants[h].prices[k].rate==null){
         flag++;
       }
       if(parseInt(postData.variants[h].prices[k].rate)> parseInt(postData.variants[h].mrp)){
         this.noexe=true;
           this.loading=true;
           alert(this.getCloseWord("MRP value must be greater than or equal to price."));
        
      
       }
       if(flag==postData.variants[h].prices.length){
         this.noexe=true;
          this.loading=true;
            this.snackbar.open(this.getCloseWord("No Rate field is fill,at least one should fill for each variant."), "", {
            duration: 3000
          });
       }

     }
     // start for member
     if(this.cust_reg_enable==true){
       //4);
     var flag=0;
        for(let k=0;k<postData.variants[h].member_prices.length;k++){
           if(postData.variants[h].member_prices[k].rate=='' || postData.variants[h].member_prices[k].rate==null){
             flag++;
           }
           if(parseInt(postData.variants[h].member_prices[k].rate)> parseInt(postData.variants[h].mrp)){
             this.noexe=true;
               this.loading=true;
               alert(this.getCloseWord("MRP value must be greater than or equal to Member Price."));
            
          
           }
           if(flag==postData.variants[h].member_prices.length){
             this.noexe=true;
              this.loading=true;
                this.snackbar.open(this.getCloseWord("No Member Rate field is fill,at least one should fill for each variant."), "", {
                duration: 3000
              });
           }

        }
      }
      else{
         for(let h=0;h<postData.variants.length;h++){
            delete postData.variants[h].member_prices;
         }
       
      }
   
   // end for member
   }

   
   if(this.noexe == false){
      postData.product_no = this.product_no;
        postData.access_token = sessionStorage.getItem("access_token");
        postData.user_num = sessionStorage.getItem("user_num");
     postData.comp_num = sessionStorage.getItem("comp_num");
    
     this.adminservice.add_product_variation(postData).subscribe(
      data => {
        this.loading=true;
        if (data["status"] == 1) {
          this.snackbar.open(this.getCloseWord("Successfully submitted product variations."), "", {
          duration: 3000
        });
          this.productsArray=data["product_no"];
          this.colors=data['color'];
        let unique = this.colors.filter((item, i, ar) => ar.indexOf(item) === i);
        this.colorArray=unique;
      
         var c=this.imageForm.get("variants") as FormArray;
          
        for(let m=0;m<this.colorArray.length;m++){
           c.push(this._formBuilder.group({
                
            color: this.colorArray[m],
            // imageShape:[''],
            
            sizes: this._formBuilder.array([this.imageplayers]) 
           
          }));
           this.imageArray22.push([]);
           this.imageArray22Preview.push([]);
          
        }
   
    this.saveCheck=false;
    this.tableCheck=false;
         
        } else {
          this.snackbar.open(this.getCloseWord("Failed to submit product variations."), "", {
          duration: 3000
        });
        }
      },
      error => {
         this.loading=true;
        this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
          duration: 3000
        });
      }
    );
   }
  }
 

  deleteRow(i) {  
  	  const control = this.tableForm.get("variants") as FormArray;
    control.removeAt(i);
     
  }

     compSettings(dd) {
    this.adminservice
      .fetch_particular_company_registry_with_sno({ comp_num: dd,s_no:7 })
      .subscribe(data => {
        if (data["status"] == 1) {
         let d = data['data'];
         let v = d.value;
         if(v== '2'){
         	this.notbuy=false;
         }
         if(v == '2' || v == '3'){
           this.rentShow = true;

         }else{
           this.rentShow = false;

         }
        } else {
          this.rentShow = false;
        }
      });
  }
   compSettings2(dd) {
    this.adminservice
      .fetch_particular_company_registry_with_sno({ comp_num: dd,s_no:16 })
      .subscribe(data => {
        if (data["status"] == 1) {
         let d = data['data'];
         let v = JSON.parse(d.value);
         if(v== "1" || v==1){
           this.ship_ask=false;
         }
         
        } else {
          
        }
      });
  }
  
  fetch_product_rates_all() {
    let postData = {
      user_num: this.user_num,
      access_token: this.access_token,
      comp_num: this.comp_num
    };

    this.adminservice.fetch_product_rates_all(postData).subscribe(
      data => {
        if (data["status"] == 1) {
          this.rates2 = data["result"];
          for(let z=0;z<this.rates2.length;z++){
          	if(this.rentShow){
          		if(this.rates2[z].is_rent == '2'){
          			this.shippingset=true;

          		}
          		if(this.rates2[z].is_rent == '1'){
          			this.onedayrentset=true;
          			this.fivedayrentset=true;

          		}
          		else if(this.notbuy){
          			if(this.rates2[z].is_rent == '1'){
          				this.sellingset=true;

          			}

          		}

          	}
          	else{
          		if(this.rates2[z].is_rent == '2'){
          			this.shippingset=true;

          		}
          		else if(this.notbuy){
          			if(this.rates2[z].is_rent == '1'){
          				this.sellingset=true;

          			}

          		}

          	}
          }
          
        } else {
        }
      },
      error => {
        this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
          duration: 3000
        });
      }
    );
  }
 

  createItem() {
    return this._formBuilder.group({
      image: [""],
      id:[""]
    });
  }
  addItem(): void {
    if (this.limitCount < 9) {
      const control = this.updateForm.get("items") as FormArray;
      control.push(this.createItem());
      this.limitCount++;
    } else {
      this.snackbar.open(this.getCloseWord("Only 8  images can upload "), "", {
        duration: 3000,
        horizontalPosition: "center"
      });
    }

  }
  removeItem(i): void {
     const control = this.updateForm.get("items") as FormArray;
    if(control.controls[i].value.id!='' && control.controls[i].value.id!=null){
       
       this.gallery_id.push({id:control.controls[i].value.id});
       }
   
    control.removeAt(i);
    this.limitCount--;
    this.imageArray2.splice(i, 1);
    this.imageArray2Preview.splice(i, 1);

  }
  getItems(form) {
    return form.controls.items.controls;
  }
  // start for color image
  fileProgressChoose(fileInput: any,i,color){

 if(fileInput.target.value!='' && fileInput.target.value!=null){
     var c=this.imageForm.get("variants") as FormArray;
         
   
    this.imgHeight=0;
       this.imgWidth=0;
       
    
    this.imageArraynew = <File>fileInput.target.files[0];
    var im=<File>fileInput.target.files[0];
  
    if(im==null){
      
    }
   
    let siz3 = Math.ceil(this.imageArraynew.size / 1024);

    var checkimg3 = this.imageArraynew.name.toLowerCase();

    if (!checkimg3.match(/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG)$/)) {
      this.snackbar.open(this.getCloseWord("Only jpg,png and jpeg file format support."), "", {
        duration: 3000,
        horizontalPosition: "center"
      });
      
      return false;
    }
    if (siz3 > 2048) {
      this.snackbar.open(this.getCloseWord("Only 2MB and less size image can upload."), "", {
        duration: 3000,
        horizontalPosition: "center"
      });
     
     
      return false;
    }
   
      let img = new Image();
      
      img.src = window.URL.createObjectURL(this.imageArraynew);
      img.onload = () => {
       this.imgHeight=img.height;
       this.imgWidth=img.width;
        this.previewArray2choose(i,color,fileInput);
     }
   }

  }
  previewArray2choose(i,color,fileInput:any) {
     var imy = <File>fileInput.target.files[0];
    var mimeType2 = imy.type;
    if (mimeType2.match(/image\/*/) == null) {
      
      return;
    }
    var reader2 = new FileReader();
      reader2.readAsDataURL(imy);
      reader2.onload = _event => {
     
      if( this.imgHeight > 5000 && this.imgWidth > 7000  ) {
        
             this.snackbar.open(this.getCloseWord('Image height should < 5000 and Width should < 7000.'),'' ,{
                duration: 5000,
                horizontalPosition:'center',
              });
             return;
          }
          else if( this.imgWidth > 7000  ) {
            
             this.snackbar.open(this.getCloseWord('Image Width should < 7000.'),'' ,{
                duration: 5000,
                horizontalPosition:'center',
              }); 
             return;
          }
           else if( this.imgHeight > 5000  ) {
              
             this.snackbar.open(this.getCloseWord('Image height should < 5000.'),'' ,{
                duration: 5000,
                horizontalPosition:'center',
              });
             return;
          }
          else{
             if(this.imageArrayChoose.length>0){
                 if(this.imageArray22[i] != null){
                
                   this.imageArrayChoose[i]=imy;
                    this.imageArrayChoosePreview[i]= reader2.result;
            
                 }
                 else{
                    this.imageArrayChoose[i]=imy;
                  
                    this.imageArrayChoosePreview[i] = reader2.result;
    
                 }
               
               
             }
             else{
                this.imageArrayChoose[i]=imy;
                  
                this.imageArrayChoosePreview[i] = reader2.result;
    
             }
            
          }
     
      };
    // }
  }

  //end for color image
  fileProgressArray2(fileInput: any,i,j,color){
     if(fileInput.target.value!='' && fileInput.target.value!=null){
     var c=this.imageForm.get("variants") as FormArray;
         
   
    this.imgHeight=0;
       this.imgWidth=0;
       
    
    this.imageArraynew = <File>fileInput.target.files[0];
    var im=<File>fileInput.target.files[0];
  
   
   
    let siz3 = Math.ceil(this.imageArraynew.size / 1024);

    var checkimg3 = this.imageArraynew.name.toLowerCase();

    if(i==0){
      if (!checkimg3.match(/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.gif|\.GIF)$/)) {
        this.snackbar.open(this.getCloseWord("Only jpg,png,gif and jpeg file format support on first index of products."), "", {
          duration: 3000,
          horizontalPosition: "center"
        });
       
       
        return false;
      }
     
    }
    else{
      if (!checkimg3.match(/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.gif|\.GIF|\.mp4|\.MP4)$/)) {
        this.snackbar.open(this.getCloseWord("Only jpg,png,mp4,gif and jpeg file format support."), "", {
          duration: 3000,
          horizontalPosition: "center"
        });
       
       
        return false;
      }
    }
   
    if (siz3 > 2048) {
      this.snackbar.open(this.getCloseWord("Only 2MB and less size image can upload."), "", {
        duration: 3000,
        horizontalPosition: "center"
      });
    
     
      return false;
    }
   
      let img = new Image();
      
      img.src = window.URL.createObjectURL(this.imageArraynew);
      img.onload = () => {
       this.imgHeight=img.height;
       this.imgWidth=img.width;
        this.previewArray2(i,j,color,fileInput);
     }
   }

  }
  previewArray2(i,j,color,fileInput:any) {
     var imy = <File>fileInput.target.files[0];
    var mimeType2 = imy.type;
    if (mimeType2.match(/image\/*/) == null) {
      
      return;
    }
    

   
      var reader2 = new FileReader();
      reader2.readAsDataURL(imy);
       // reader2.readAsDataURL(this.imageArray22[j][i]);
      reader2.onload = _event => {
       

     
      if( this.imgHeight > 5000 && this.imgWidth > 7000  ) {
        
             this.snackbar.open(this.getCloseWord('Image height should < 5000 and Width should < 7000.'),'' ,{
                duration: 5000,
                horizontalPosition:'center',
              });
             
        return;
          }
          else if( this.imgWidth > 7000  ) {
            
             this.snackbar.open(this.getCloseWord('Image Width should < 7000.'),'' ,{
                duration: 5000,
                horizontalPosition:'center',
              }); 
           
            return;
          }
           else if( this.imgHeight > 5000  ) {
              
             this.snackbar.open(this.getCloseWord('Image height should < 5000.'),'' ,{
                duration: 5000,
                horizontalPosition:'center',
              });
          //     this.imageArray22[j][i]=null;
          // this.imageArray22Preview[j][i]=null;
     
              return;
          }
          else{
             if(this.imageArray22.length>0){
               if(this.imageArray22[j].length>0){
                 if(this.imageArray22[j][i] != null){
                
                   this.imageArray22[j][i]=imy;
                    this.imageArray22Preview[j][i]= reader2.result;
            
                 }
                 else{
                    this.imageArray22[j][i]=imy;
                  
                    this.imageArray22Preview[j][i] = reader2.result;
    
                 }
               }
               else{
                  this.imageArray22[j][i]=imy;
                  
                  this.imageArray22Preview[j][i] = reader2.result;
    
               }
             }
             else{
                this.imageArray22[j][i]=imy;
                  
                this.imageArray22Preview[j][i] = reader2.result;
    
             }
            
      
             
          }
     
      };
    // }
  }

    add_product_variation_image2(j,color){
   var index=0;
   for(let k=0;k<this.product_imag_id.length;k++){
          if(this.product_imag_id[k]!=null){
            index=1;
          }
        }
    this.loading=false;
     var formData = new FormData();
      // start clear background
    if(this.is_checked==true){
          formData.append("clear_bg",'1');
    
      }
      else{
         formData.append("clear_bg",'0');
      }
       // end clear background

   
     if(this.imageArray22[j].length<1 && index==0){
        this.loading=true;
       this.snackbar.open(this.getCloseWord("At least 1 image upload compulsory."), "", {
        duration: 3000,
        horizontalPosition: "center"
      });
     }
      else if(this.imageArray22[j].length > 9 && index==0){
         this.loading=true;
       this.snackbar.open(this.getCloseWord("Maximum 9 images can upload."), "", {
        duration: 3000,
        horizontalPosition: "center"
      });
     }
    else if((this.imageArray22[j].length>=1 && this.imageArray22[j].length <= 9) || index==1){
        this.loading=false;
        for (let count = 0; count < this.imageArray22[j].length; count++) {
          if (this.imageArray22[j][count] == null) {
             this.imageArray22[j].splice(count, 1);
     
           
          }
        }
         for (let count2 = 0; count2 < this.imageArray22[j].length; count2++) {
         
             // this.imageArray22[j].splice(count, 1);
     
            formData.append(
              "image" + count2,
              this.imageArray22[j][count2],
              this.imageArray22[j][count2].name
            );
         
        }
      //   if(this.imageArrayChoose.length>0 && this.imageArrayChoose[j]!=null){
      //   formData.append(
      //         "imageShape" ,
      //         this.imageArrayChoose[j],
      //         this.imageArrayChoose[j].name
      //       );
      // }
if(this.product_imag_id.length>0){
              formData.append("id",JSON.stringify(this.product_imag_id));
}
            formData.append("color",color);
    // 
    // formData.append("id",JSON.stringify(this.imagPush));
          formData.append("product_no", this.product_no);
          formData.append("user_num", this.user_num);
          formData.append("access_token", this.access_token);
          formData.append("comp_num",this.comp_num);
         this.adminservice.add_product_variation_images(formData).subscribe(
          data => {
            // this.loading=true;
            this.loading=true;
            if (data["status"] == 1) {
               this.loading=true;
    
              this.snackbar.open(this.getCloseWord("Successfully uploaded images."), "", {
              duration: 3000
            });
              if(this.imageArray22.length == 1){
    
                this.router.navigate(["/list-product"]);
              }
              else{
    
                     var control = this.imageForm.get("variants") as FormArray;
                    control.removeAt(j);
                     this.imageArray22.splice(j, 1);
                    this.imageArray22Preview.splice(j, 1);
                      this.product_imag_id=[];

              }
             
            } else {
    
              this.snackbar.open(this.getCloseWord("Failed to upload product images."), "", {
              duration: 3000
            });
            }
          },
          error => {
             this.loading=true;
            this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
              duration: 3000
            });
          }
        );
         this.loading=true;
      }
      // start 31/08/2020
    else if(this.imageArray22[j].length<1 ){
        this.loading=true;
       this.snackbar.open(this.getCloseWord("At least 1 image upload compulsory."), "", {
        duration: 3000,
        horizontalPosition: "center"
      });
     }
      else if(this.imageArray22[j].length > 9){
         this.loading=true;
       this.snackbar.open(this.getCloseWord("Maximum 9 images can upload."), "", {
        duration: 3000,
        horizontalPosition: "center"
      });
     }
    else if(this.imageArray22[j].length>=1 && this.imageArray22[j].length <= 9){
        this.loading=false;
        for (let count = 0; count < this.imageArray22[j].length; count++) {
          if (this.imageArray22[j][count] == null) {
             this.imageArray22[j].splice(count, 1);
     
           
          }
        }
         for (let count2 = 0; count2 < this.imageArray22[j].length; count2++) {
         
             // this.imageArray22[j].splice(count, 1);
     
            formData.append(
              "image" + count2,
              this.imageArray22[j][count2],
              this.imageArray22[j][count2].name
            );
         
        }

            formData.append("color",color);
    // 
    // formData.append("id",JSON.stringify(this.imagPush));
          formData.append("product_no", this.product_no);
          formData.append("user_num", this.user_num);
          formData.append("access_token", this.access_token);
          formData.append("comp_num",this.comp_num);
         this.adminservice.add_product_variation_images(formData).subscribe(
          data => {
            // this.loading=true;
            this.loading=true;
            if (data["status"] == 1) {
               this.loading=true;
    
              this.snackbar.open(this.getCloseWord("Successfully uploaded images."), "", {
              duration: 3000
            });
              if(this.imageArray22.length == 1){
    
                this.router.navigate(["/list-product"]);
              }
              else{
    
                     var control = this.imageForm.get("variants") as FormArray;
                    control.removeAt(j);
                     this.imageArray22.splice(j, 1);
                    this.imageArray22Preview.splice(j, 1);
                      this.product_imag_id=[];

              }
             
            } else {
    
              this.snackbar.open(this.getCloseWord("Failed to upload product images."), "", {
              duration: 3000
            });
            }
          },
          error => {
             this.loading=true;
            this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
              duration: 3000
            });
          }
        );
         this.loading=true;
      }
      else{
        this.loading=true;
        this.snackbar.open(this.getCloseWord("Something Went wrong ,please try again."), "", {
              duration: 3000
            });
      }

      
      //end 31/08
     

  }
  
  
  createItemR() {
    return this._formBuilder.group({
      rate: [""],
      id: [""]
    });
  }
  
  removeItemR(i): void {
    const control = this.updateFormR.get("rates") as FormArray;
    control.removeAt(i);
    this.limitCountRate--;
    this.ratingcount=true;
  }
  getItemsR(form) {
    return form.controls.rates.controls;
  }

  fetch_product(){
  		this.adminservice.getProduct({access_token : this.access_token , user_num : this.user_num , product_no : this.product_no}).subscribe(
      data => {       
        if (data["status"] == 1) {
          this.product = data['product'];
          this.returns=this.product.return_days;
          this.mrpset=this.product.mrp;
          this.skuset=this.product.sku;
           this.prod_groupset=this.product.prod_group;
          this.taxset=this.product.tax_num;
          if(this.taxset == '0' || this.taxset == null){
            this.taxset=1;
          }
          this.qtyset=this.product.txn_quantity;

          this.product_rate = data['product_rate'];
          this.product_imag=data['product_image'];
          if(this.product_imag.length>0){
            this.existImage=true;
            for(let im=0;im<this.product_imag.length;im++){
              this.product_imag_prev[im]=this.product_imag[im].image_link;
            }
          }

       
        }else if(data['status']==10) {
          sessionStorage.clear();
         this.snackbar.open(this.getCloseWord('Multiple login with this ID has been detected, Logging you out.'),'' ,{
                  duration: 3000,
                  horizontalPosition:'center',
          });      
        this.router.navigate(['/']);
        }
      },
      error => {
        this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
          duration: 3000
        });
      }
    );
  }  
// 31/08/2020 start
onChangeDemoC(checked:boolean,i){
 if (checked) {
      this.product_imag_id[i] = this.product_imag[i].id;
    } else {
      this.product_imag_id[i] = null;
    }
}
// 31/08/2020 end
  clicking(){
  }
  fetch_product_tax_all(){
      let postData = {
      user_num: this.user_num,
      access_token: this.access_token,
    };

    this.adminservice.fetch_product_tax_all(postData).subscribe(
      data => {
        if (data["status"] == 1) {
          this.taxes = data["result"];
          
        } else {
        }
      },
      error => {
        this.snackbar.open(this.getCloseWord("Something Went wrong please try again."), "", {
          duration: 3000
        });
      }
    );
  }
  
back(){
   sessionStorage.setItem("product_no", this.product_no);
    this.router.navigate(["/short-product-update"]);
  
}

  compSettingsCustReg(comp_num) {
    //1);
    let post={ comp_num: comp_num,s_no:21};
    this.adminservice
      .fetch_particular_company_registry_with_sno({ comp_num: comp_num,s_no:21})
      .subscribe(data => {
     


        if (data["status"] == 1) {
             if(data['data'].value==1 || data['data'].value=='1'){
                  this.cust_reg_enable=true;
                }
            
         }
      });
  }
// start background clear image
  onChangeDemo(checked: boolean) {
    if (checked) {
      this.is_checked = true;
    } else {
      this.is_checked = false;
    }
  }
// end background clear image
// start for variant color
 compSettingsColor(dd) {
    this.adminservice
      .fetch_particular_company_registry_with_sno({ comp_num: dd,s_no:39 })
      .subscribe(data => {
        if (data["status"] == 1) {
         let d = data['data'];
         
          this.variant_color=JSON.parse(data['data'].value);
        } else {
          
        }
      });
  }
// end for variant color
// start for variant size
compSettingsSize(dd) {
    this.adminservice
      .fetch_particular_company_registry_with_sno({ comp_num: dd,s_no:40 })
      .subscribe(data => {
        if (data["status"] == 1) {
         let d = data['data'];
         
          this.variant_size=JSON.parse(data['data'].value);
        } else {
          
        }
      });
  }
// end for variant size
getCloseWord(key: string): string {
  var translateValue='';
 this.translate.get(key).subscribe((res: string) => {
   translateValue=res
});
return translateValue;
}
}

