export const environment = {
  production: true,
  urls:'/',
  mock: false,
  
  development: false,
  title: 'prod',
  NodebaseUrl:'https://api2.ecomtrails.com/',
  project:"ecomtrails",
  favicon:"assets/img/ecomfavicon1.png",
  logo:"assets/img/ecom-logo.png",
  loader:"assets/img/loader-ecom.gif",
  error_img:"assets/img/ecomfavicon1.png",
  website:"ecomtrails.com",
  admintitle:"EcomTrails | Best Ecommerce Store | Start Selling",
  admindescription:"The best E-Commerce store that helps you to Sell Online Selling online with Ecomtrails been easier, faster more scalable. An online store to build, manage, and grow your business.",

  UploadUrl : 'https://909corns.in/uploads/', 
  firebase  :{
    apiKey: "AIzaSyDGvvoJf9SlKsC5JS-DdOzJa2JFLNf4fYQ",
    authDomain: "ecomtrails.firebaseapp.com",
    databaseURL: "https://ecomtrails.firebaseio.com",
    projectId: "ecomtrails",
    storageBucket: "ecomtrails.appspot.com",
    messagingSenderId: "918450948543",
    appId: "1:918450948543:web:fa17d5872d02cde555b90a",
    measurementId: "G-KL5BVT2ZG5"
  }
};
